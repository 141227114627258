@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust:     100%;
}

body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

a {
    background-color: transparent;
}

a:active,
a:hover {
    outline: 0;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

mark {
    background: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

button,
input,
select,
textarea {
    color: #404040;
    font-size: 14px;
    line-height: 1.5;
}

body {
    background-color: #fff;
    color: #6e6e6e;
    font-size: 15px;
    line-height: 24px;
    margin: 0;
    font-family: "Open Sans",sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0 0 15px 0;
}
h1, h2, h3, h4, h5, h6 {
    color: #121212;
    font-family: "Open Sans",sans-serif;
    font-weight: 600;
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: 24px;
}

h3{
    font-size: 20px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

dfn,
cite,
em,
i {
    font-style: italic;
}

blockquote {
    margin: 0 1.5em;
}

address {
    margin: 0 0 1.5em;
}

pre {
    background: #eee;
    font-family: "Courier 10 Pitch", Courier, monospace;
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1.6em;
}

code,
kbd,
tt,
var {
    font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
    font-size: 15px;
    font-size: 0.9375rem;
}

code {
    padding: 2px 4px;
    font-family: monospace, monospace;
    font-size: 1em;
    background-color: #fbfbfb;
    border-radius: 4px;
    border: 1px solid #eee;
}

abbr,
acronym {
    border-bottom: 1px dotted #666;
    cursor: help;
}

mark,
ins {
    background: #fff9c0;
    text-decoration: none;
}

big {
    font-size: 125%;
}

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/

html {
    box-sizing: border-box;
}

*,
*::before,
*::after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
    box-sizing: inherit;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: "";
}

blockquote,
q {
    quotes: "" "";
}

hr {
    background-color: #ccc;
    border: 0;
    height: 1px;
    margin-bottom: 1.5em;
}

ul,
ol {
    margin: 0 0 1.5em 3em;
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

li > ul,
li > ol {
    margin-bottom: 0;
    margin-left: 1.5em;
}

ul, ol {
    margin: 0 0 0.5em 1em;
    padding: 0;
}

dt {
    font-weight: bold;
}

dd {
    margin: 0 1.5em 1.5em;
}

img {
    height: auto; /* Make sure images are scaled correctly. */
    max-width: 100%; /* Adhere to container width. */
}

table {
    margin: 0 0 1.5em;
    width: 100%;
}


td, th {
    border: 1px solid #ddd;
    padding: 5px;
}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
button,
.comment-reply-link,
 input[type="button"],
 input[type="reset"],
 input[type="submit"] {
    background: #AE1715 none repeat scroll 0 0;
    border: medium none;
    color: #ffffff;
    font-size: 15px;
    line-height: 1.5;
    padding: 5px 15px;
}


button:hover,
.comment-reply-link,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
    background-color: #AE1715 ;
}

button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
button:active,
input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
    border-color: #aaa #bbb #bbb;
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15);
    outline: none;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea,
select {
    color: #666;
    border: 1px solid #ccc;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
    color: #111;
    outline:none;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"]{
    padding: 6px 0 6px 9px;
         -webkit-appearance: none;
  -webkit-border-radius: 0;
}

select {
    width: 100%;
    padding:5px;
}

textarea {
    padding:10px 20px;
    width: 100%;
}
/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/

a {
    color: #AE1715 ;
    text-decoration: inherit;
}

a:visited {
    color: #AE1715 ;
}

a:hover,
a:focus,
a:active {
    color:#AE1715;
}

a:focus {
    outline:inherit;
}

a:hover,
a:active {
    outline: 0;
}

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/




#main-nav {
    display: block;
    float: right;
    transition: all 0.5s ease-in-out 0s;
    width: auto;
}

.enabled-sticky-primary-menu #main-nav.fixed {
    box-shadow: 0 0 3px #aaaaaa;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.main-navigation ul {
    display: block;
    margin: 0;
    padding: 0;
}

.main-navigation ul ul {
    background-color: #ffffff;
    box-shadow: 0 1px 2px;
    left: -9999px;
    margin: 0;
    min-width: 200px;
    opacity: 0;
    position: absolute;
    top: 65px;
    transition: opacity 0.4s ease-in-out 0s;
    z-index: -9999;
}

.main-navigation ul ul ul {
    left:100%;
    top: 0
}

.main-navigation li {
    position: relative;
    display: inline-block;
    margin-bottom:0;
    float: left;
}

.main-navigation ul > li:hover > ul {
    opacity: 1;
    left:0;
    z-index: 9999;
}

.main-navigation ul ul ul ul  {
    display: none;
}
.main-navigation ul ul ul li:hover > ul {
    display: block;
}
.main-navigation ul ul li:hover > ul {
    opacity: 1;
    left:100%;

}
.main-navigation li li {
    float: none;
    display: block;
}

.main-navigation ul li li:first-child > a {
    border-left: none;
}

.main-navigation ul li a {
    color: #6e6e6e;
    display: block;
    font-size: 14px;
    font-weight: normal;
    padding: 23px 15px;
    position: relative;
    z-index: 99;
    text-transform: uppercase;
}


.main-navigation ul li.current-menu-item a,
.main-navigation ul li a:hover,
.main-navigation ul li a.active {
    color:#AE1715;
}


.main-navigation ul ul a {
    border-bottom: 1px solid #ddd;
    border-left: medium none;
    border-right: medium none;
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.18) inset;
    font-size: 14px;
    height: auto;
    padding: 9px 15px;
    text-align: left;
    width: 100%;
}
.main-navigation ul li.menu-item-has-children ul.sub-menu li a,
.home.page .slider-enabled.header-collapse ul li a{
    color: #222222;
}

.home.page .slider-enabled.header-collapse ul li a:hover{
    color: #AE1715;
}

.main-navigation ul li.menu-item-has-children > a::after, .main-navigation ul li.page_item_has_children > a::after {
    content: "";
    font-family: FontAwesome;
    margin-left: 10px;
    margin-right: 0;
    position: absolute;
    right: 10px;
    top: 23px;
}

.main-navigation ul li li.menu-item-has-children > a::after,
.main-navigation ul li li.page_item_has_children > a::after {
    content: "\f105";
    top: 10px;
}

.main-navigation ul li.menu-item-has-children a,
.main-navigation ul li.page_item_has_children a {
    padding-right: 30px;
}

.main-navigation .menu-toggle,
.main-navigation .dropdown-toggle {
    display: none;
}
#mobile-trigger {
  display: none;
}
#mobile-menu {
  display: none;
}

/*comment and post navigation*/

.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
    margin: 0 0 1.5em;
    overflow: hidden;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous,
.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next,
#infinite-handle span {
    float: left;
    padding:0px;
    width: auto;
    line-height: 1.5;
}

#infinite-handle span {
    background: #AE1715;
}

#infinite-handle span {
    font-size: 1.5;
    font-size: 15px;
    color: #fff;
}
#infinite-handle{
	margin-bottom: 20px;
	height: auto;
	overflow: hidden;
}

 #infinite-handle span:hover {
    background: #AE1715;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
    float: left;

}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
    float: right;

}

.comment-navigation .nav-previous a::before,
.posts-navigation .nav-previous a::before,
.post-navigation .nav-previous a::before {
    content: "\f100";
    display: inline-block;
    font-family: FontAwesome;
    margin-right: 10px;
    color: #121212;
}

.comment-navigation .nav-next a::after,
.posts-navigation .nav-next a::after,
.post-navigation .nav-next a::after {
    content: "\f101";
    display: inline-block;
    font-family: FontAwesome;
    margin-left: 10px;
    color: #121212;
}

.page-links {
    clear: both;
    margin: 0 0 1.5em;
}

.comment-navigation .nav-next a,
.comment-navigation .nav-previous a,
.nav-links .nav-previous a,
.nav-links .nav-next a{
    background: transparent; 
    color: #121212;
    border:0;
    padding: 14px 0px;
    border-radius: 3px;
    line-height: 1.1;
    display: block;
    font-size: 18px;
}

.nav-links .nav-previous a:hover,
.nav-links .nav-next a:hover {
    background: transparent;
    color: #AE1715;
}

.comment-navigation .nav-next a:hover:after,
.comment-navigation .nav-previous a:hover:before,
.nav-links .nav-previous a:hover:before,
.nav-links .nav-next a:hover:after {
    color: #AE1715;
}

.nav-links{
    border-top: medium none;
    margin-bottom: 20px;
    padding: 20px 0;
    overflow: hidden;
}

.nav-links .page-numbers {
    border: 1px solid;
    padding: 5px 10px;
}

.nav-links .page-numbers.current,.nav-links a.page-numbers:hover {
    background: #AE1715 none repeat scroll 0 0;
    border-color: #AE1715 ;
    color: #ffffff;
}
.nav-links .page-numbers.dots {
    border: none;
}









/* #######################################################################

        meanMenu
        --------
        
        To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
    display: none;
}
/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
    float: left;
    position: absolute;
    width: 100%;
    z-index: 999999;
    left: 0;
}

.mean-container a.meanmenu-reveal {
    color: #1e435a;
    cursor: pointer;
    display: block;
    font-weight: 700;
    height: 20px;
    line-height: 20px;
    padding: 20px 15px;
    position: absolute;
    right: 0;
    text-decoration: none;
    top: 0;
    width: 27px;
    left: auto;
    text-align: center;
    text-indent: 0px;
    font-size: 18px;
}
.mean-container a.meanmenu-reveal span {
    background: #fff;
    display: block;
    height: 1px;
    margin-top: 8px
}
a.meanmenu-reveal.meanclose {
    color:#fff;
}
.mean-container .mean-nav {
    background:#2c4450;
    float: left;
    margin-top: 60px;
    position: relative;
    width: 100%;
    z-index: 9999999;
}	
.mean-container .mean-nav ul {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style-type: none;
}

.mean-container .mean-nav ul li {
    float: left;
    margin: 0;
    position: relative;
    width: 100%;
}

.mean-container .mean-nav ul li a {
    display: block;
    float: left;
    width: 90%;
    padding: 1em 5%;
    margin: 0;
    text-align: left;
    color: #fff;
    border-bottom: 1px solid #383838;
    border-bottom: 1px solid rgba(255,255,255,0.5);
    text-decoration: none;
    font-family:'Lato', Open Sans, sans-serif;
}

.mean-container .mean-nav ul li li a {
    width: 80%;
    padding: 1em 10%;
    border-bottom: 1px solid #f1f1f1;
    border-bottom: 1px solid rgba(255,255,255,0.25);
    opacity: 0.75;
    filter: alpha(opacity=75);
    text-shadow: none !important;
    visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a {
    border-bottom: none;
    margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
    width: 70%;
    padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a {
    width: 60%;
    padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
    width: 50%;
    padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
    background: #252525;
    background: rgba(255,255,255,0.1);
}

.mean-container .mean-nav ul li a.mean-expand {
    width: 26px;
    height: 26px;
    border: none !important;
    padding: 12px !important;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    font-weight: 700;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
    background: none;
}

.mean-container .mean-push {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    clear: both;
}

.mean-nav .wrapper {
    width: 100%;
    padding: 0;
    margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar, .mean-container .mean-bar * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.mean-container a.meanmenu-reveal span:first-child {
    margin: 0;
}






/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/

/* Text meant only for screen readers. */
.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;/* It should be ablolute */
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important; /* It should be clip auto */
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; /* Above WP toolbar. */
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
}
.wp-block-image.is-resized.alignleft{
    margin-right: 1.5em;
}

.alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
}
.wp-block-image.is-resized.alignright{
    margin-left: 1.5em;
}

.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
}

.alignnone{
    clear: both;
    /*display: inline-block;*/
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    margin-top: 1.5em;

}

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/

.clear-fix::before,
.clear-fix::after,
.entry-content::before,
.entry-content::after,
.comment-content::before,
.comment-content::after,
.site-header::before,
.site-header::after,
.site-content::before,
.site-content::after,
.site-footer::before,
.site-footer::after,
#home-page-widget-area::after,
#home-page-widget-area::before,
#featured-slider::after,
#featured-slider::before {
    content: "";
    display: table;
}

.clear-fix::after,
.entry-content::after,
.comment-content::after,
.site-header::after,
.site-content::after,
.site-footer::after {
    clear: both;
}


/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget {
    margin:30px 0 0;
}
.widget:first-child {
    margin: 0;
}

/* Make sure select elements fit in widgets. */

.widget select {
    max-width: 100%;
}

.section-title,
.section-title .seperator {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
}

.section-title .seperator  {
    margin-bottom: 5px;
}

.section-title .seperator span {
    display: inline-block;
    position: relative;
}

.section-title .seperator span:before,
.section-title .seperator span:after{
    background-color: #ddd;
    content: "";
    height: 1px;
    position: absolute;
    top: 50%;
    width: 60px;
    position: absolute;
}

.section-title .seperator span:before{
    right: 100%;
}

.section-title .seperator span:after {
    left: 100%;
}

.section-title .seperator i {
    color:#AE1715;
    font-size: 20px;
    font-family: "FontAwesome"; 
    padding: 5px 15px;
    display: inline-block;
}


#home-page-widget-area .widget-title {
    color: #303133;
    display: block;
    font-size: 35px;
    font-weight: 400;
    margin-bottom: 10px;
    overflow: hidden;
    padding-left: 0px;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 1px;
    line-height: 1;
}


#home-page-widget-area .widget-title span{
    position: relative;
}

#home-page-widget-area .widget-title span::before {
    background-color: #fff;
    content: "";
    height: 1px;
    position: absolute;
    top: 0;
    width: 100px;
    right: 100%;
    margin-right: 30px;
    top: 46%;
}

#home-page-widget-area .widget-title span::after {
    background-color: #fff;
    content: "";
    height: 1px;
    position: absolute;
    top: 0;
    width: 100px;
    left: 100%;
    margin-left: 30px;
    top: 46%;
}

#home-page-widget-area .bg_enabled .widget-title span::before,
#home-page-widget-area .bg_enabled .widget-title span::after,
#home-page-widget-area .business_point_widget_services .widget-title span::before,
#home-page-widget-area .business_point_widget_services .widget-title span::after,
#home-page-widget-area .business_point_widget_latest_news .widget-title span::before,
#home-page-widget-area .business_point_widget_latest_news .widget-title span::after{
	background-color:#AE1715;
}

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
    display: block;
}

.hentry {
    margin: 0 0 1.5em;
}

.updated:not(.published) {
    display: none;
}

.single .byline,
.group-blog .byline {
    display: inline;
}

.page-content,
.entry-content,
.entry-summary {
    margin: 1.5em 0 0;
}

.entry-meta > span,
.entry-footer > span,
.single-post-meta > span {
    margin: 0 10px 0 0;
}

.entry-meta > span a, .single-post-meta > span a, .entry-footer > span a {
    color: #686868;
    font-style: italic;
}

.page-content,
.entry-content,
.entry-summary {
    margin: 15px 0 0;
}

.entry-meta > span::before,
.entry-footer > span::before,
.single-post-meta > span::before {
    display: inline-block;
    font-family: FontAwesome;
    height: 15px;
    margin-right:8px;
    content: "";
    color:#AE1715;
}

.entry-meta .byline .author.vcard {
    margin-left: -4px;
}

.entry-footer span {
    margin-right: 15px;
}

.entry-footer span.author.vcard {
    margin-right: 0px;
}

span.comments-link::before {
    content: "\f086";
}

.entry-meta .posted-on::before,
.single-post-meta .posted-on::before {
    content: "\f073 ";
}

.entry-footer > .cat-links::before {
    content: "\f07c";
}

.entry-meta .byline::before,
.single-post-meta .byline::before {
    content: "\f007";
}

.entry-footer .edit-link::before{
    content: "\f044";
}

.entry-footer .tags-links::before{
    content: "\f02c";
}


/*--------------------------------------------------------------
## Asides
--------------------------------------------------------------*/
.blog .format-aside .entry-title,
.archive .format-aside .entry-title {
    display: none;
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.authorbox .author-avatar {
    float: left;
    margin: 5px 15px 10px 0;
}
.authorbox {
    background:#e6ffff;
    border: 1px solid #dddddd;
    margin-top: 20px;
    overflow: hidden;
    padding: 15px;
}

.authorbox.no-author-avatar .author-info {
    margin-left: 0;
}

.authorbox .author-bio-posts-content {
    margin-top: 10px;
}

.authorbox .author-bio-posts-content p {
    margin: 0;
}

.authorbox .author-bio-posts-content ul {
    list-style-position: inside;
    margin: 0;
}

#respond {
    clear: both;
    display: block;
    float: left;
    width: 97%;
}

#commentform label {
    display: inline-block;
}

.comment-content a {
    word-wrap: break-word;
}

.bypostauthor {
    display: block;
}

.comments-area form#commentform p {
    float: left;
    width: 33.33%;
}

.comments-area form#commentform p.comment-form-cookies-consent,
.comments-area #commentform p.comment-notes,
.comments-area #commentform p.comment-form-comment {
    width: 100%;
}

.comments-area form#commentform p.comment-form-cookies-consent input[type="checkbox"] {
    margin-right: 10px;
    margin-top: 6px;
    float: left; 
}

.comment-content a {
    word-wrap: break-word;
}

.bypostauthor {
    display: block;
}

.comment-form {
    margin: 0 -15px;
}

.comments-area form#commentform p.logged-in-as ,
.comments-area form#commentform p{
    padding: 0 15px;
}
.comment-form-author input,
.comment-form-email input,
.comment-form-url input{
    width: 100%;
}
#tab-reviews .comment-form-author,
#tab-reviews .comment-form-email,
#tab-reviews .comment-form-url{
    width: 50%;
}

.comments-area form#commentform p.logged-in-as {
    padding: 0 15px;
    width: 100%;
}

.comment-form-author,
.comment-form-email,
.comment-form-url{
    width: 33.33%;
    float: left;
}

.comment-list li.comment::after {
    content: inherit;
    display: none;
}

.comment-list li{
    padding-left: 0;
}

.comment {
    background-position: 0 -74px;
    display: block;
    float: left;
    width: 100%;
}

ol.comment-list .comment{
    margin-bottom: 15px;
}

ol.comment-list .comment:first-child {
    margin-bottom: 0px;
}

ol.comment-list {
    border-radius: 5px;
    float: left;
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
    overflow: inherit;
    padding: 0;
    width: 100%;
}

ol.comment-list .children {
    border: medium none;
    float: left;
    margin: 15px 0 5px 15px;
    width: 98%;
}

ol.comment-list li {
    list-style: outside none none;
    margin-bottom: 0px;
}

.comment-list .comment-content ul,
.comment-list .comment-content ul li  {
    list-style: disc;
}

.comment-list .comment-content ol,
.comment-list .comment-content ol li  {
    list-style: decimal;
}

.comment .comment-body {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 0;
    clear: both;
    display: block;
    float: left;
    margin-top: 5px;
    padding: 10px 30px 10px 30px;
    position: relative;
    text-align: left;
    width: 100%;
}

.comment-author.vcard {
    font-size: 20px;
    margin-bottom: 5px;
}

.comment-list .children {
    background-position: left 20px;
    background-repeat: no-repeat;
    border-left: 1px solid rgb(238, 238, 238);
    margin-left: 0;
    padding-left: 40px;
}

.comment-list li.comment > div img.avatar {
    left: 29px;
    position: absolute;
    top: 29px;
}

#comment-form-title {
    font-size: 24px;
    padding-bottom: 10px;
}

.vcard .avatar {
    float: left;
    margin: 7px 15px 15px 0;
}

.comment-metadata {
    font-size: 13px;
    font-style: italic;
    line-height: 16px;
    margin-bottom: 10px;
}

.comment-reply-link {
    color: #ffffff;
    display: inline-block;
    float: none;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 10px;
    padding: 1px 15px 3px;
    border-radius: 2px;
}

a.comment-reply-link:hover {
    color: #fff;
}

.form-allowed-tags code {
    white-space: inherit;
    word-wrap: break-word;
}

.comment-respond label {
    display: block;
    font-weight: normal;
}

.comments-title {
    float: left;
    font-weight: normal;
    margin: 25px 0 15px 0px;
}

.comment-content a {
    word-wrap: break-word;
}

.bypostauthor {
    display: block;
}

#commentform label {
    display: inline-block;
}

#commentform  input[type="submit"] {
    padding: 14px 45px;
    border-radius: 3px;
    line-height: 1.1;
    border:1px solid #AE1715;
}

#commentform  input[type="submit"]:hover {
    background: transparent;
    color: #AE1715;
}

/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/

/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation, /* Older / Newer Posts Navigation (always hidden) */
.infinite-scroll.neverending .site-footer { /* Theme Footer (when set to scrolling) */
    display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
    display: block;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
    max-width: 100%;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
    margin-bottom: 1.5em;
    max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption .wp-caption-text {
    margin: 0.8075em 0;
}

.wp-caption-text {
    text-align: center;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
    margin-bottom: 1.5em;
}

.gallery-item {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;
    margin: 0;
    line-height: 0;
    padding: 0px 5px 5px 0px;
}

.gallery-columns-2 .gallery-item {
    max-width: 50%;
}

.gallery-columns-3 .gallery-item {
    max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
    max-width: 25%;
}

.gallery-columns-5 .gallery-item {
    max-width: 20%;
}

.gallery-columns-6 .gallery-item {
    max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
    max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
    max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
    max-width: 11.11%;
}

.gallery-caption {
    display: block;
}


/*--------------------------------------------------------------
# Custom
--------------------------------------------------------------*/

#page {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
}

.site-layout-boxed #page {
    box-shadow: 0 0 1px #999999;
    width: 1255px;
    margin-top: 35px;
    margin-bottom: 35px;
}

.container {
    width: 1200px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.inner-wrapper {
    margin-left: -15px;
    margin-right: -15px;
}

.v-center{
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}

.button {
    background: #AE1715;
    color: #fff;
    display: inline-block;
    padding:14px 45px;
    font-size: 15px;
    border:1px solid #AE1715; 
    border-radius: 3px; 
    line-height: 1.1;
}

.button:hover {
    background: transparent;
}

.button,
.button:hover {
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

p:empty {
    display: none;
}
/*--------------------------------------------------------------
##  Header Style
--------------------------------------------------------------*/

.custom-logo-link,
.site-logo-link,
#site-identity {
    float: left;
    margin-right: 15px;
}

.custom-logo-link,.site-logo-link {
    max-width: 300px;
}

.site-info a{
    color: #fff;
}

.site-header {
    position: relative;
}

#masthead {
    float: left;
    z-index: 999;
    padding: 20px 0px;
    position: relative;
    width: 100%;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

.site-title > a {
    color: #222;
}
.site-title {
    /*color: #282828;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    font-family: "Open Sans",sans-serif;*/
    color: #282828;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
    font-family: "Open Sans",sans-serif;
    margin-top: 8px;
}
.site-branding {
    float: left;
    margin-right: 30px;
}
.site-description {
    color: #222;
    font-size: 15px;
    font-style: inherit;
    margin-bottom: 0px;
    font-weight: 400;
}

.home.page-template-home .slider-enabled .site-title > a,
.home.page-template-home .slider-enabled .site-title,
.home.page-template-home .slider-enabled .site-description,
.home.page-template-default .site-title > a,
.home.page-template-default .site-title,
.home.page-template-default .site-description,
.home.blog .site-title a,
.home.blog .site-description {
	color: #282828;
}

#masthead.header-collapse{
    background: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    z-index: 9999;
}

#masthead.header-collapse .site-title > a,
#masthead.header-collapse .site-description {
	color: #282828;
}

.right-head {
    float: right;
    padding-top: 20px;
}

.social-links {
    float: right;
}

.search-box {
    float: right;
    position: relative;
}

.search-box > a {
    background: #AE1715 none repeat scroll 0 0;
    color: #ffffff;
    display: block;
    padding: 6.2px 8.7px;
}

.search-box > a i {
    font-size: 17px;
}

.search-box > a:hover {
    color: #fff;
    background-color: #AE1715;
}

.search-box .search-box-wrap {
    background: #ffffff none repeat scroll 0 0;
    top: 45px;
    box-shadow: 0 1px 2px;
    display: none;
    padding: 10px;
    position: absolute;
    right: 7%;
    width: 300px;
    z-index: 9999;
}

.search-box .search-box-wrap {
    right: 3%;
    width: 300px;
}

.search-form input.search-field {
    background: #f9f9f9;
    padding: 10px 9% 10px 10px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.search-form input.search-field:focus {
    background: #ebffff;
}

.search-submit {
    border-radius: 0 ;
    padding: 7.3px 20px;
    position: absolute;
    right: 0;
    top: 0;
    height: 44px;
}

.search-form {
    position: relative;
}

/*--------------------------------------------------------------
## Social Links Style
--------------------------------------------------------------*/

.business_point_widget_social {
    clear: both;
    overflow: hidden;
}

.business_point_widget_social ul {
    margin: 0;
}

.business_point_widget_social li a {
    border: 1px solid #bababa;
    display: inline-block;
    height: 33px;
    line-height: 1.7;
    margin-right: 0;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    width: 33px;

    -webkit-transform: rotate(45deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
      -ms-transform: rotate(45deg); /* IE 9 */
          transform: rotate(45deg); /* IE 10, Fx 16+, Op 12.1+ */
}

.business_point_widget_social li {
    display: block;
    float: left;
    margin:10px;
}

.widget-area .business_point_widget_social li,
#footer-widgets .business_point_widget_social li {
    padding: 0;
}

.widget-area .business_point_widget_social li::before,
#footer-widgets .business_point_widget_social li::before {
    display: none;
}

.business_point_widget_social ul li a::before {
    font-size: 18px;
}

.business_point_widget_social li a i {
    color:#cacad8;
    font-size: 18px;
    font-weight: normal;
}

.business_point_widget_social ul li a::before {
    color: #8c8c8c;
    content: "\f0c1";
    display: block;
    font-family: FontAwesome;
    font-weight: normal;
    line-height: 1.7;

    -webkit-transform: rotate(-45deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
      -ms-transform: rotate(-45deg); /* IE 9 */
          transform: rotate(-45deg); /* IE 10, Fx 16+, Op 12.1+ */
}

.business_point_widget_social ul li a:hover {
    background-color: #AE1715;
    border: 1px solid rgba(0, 0, 0, 0);
    -moz-transition: all 0.4s ease-out 0s;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.business_point_widget_social ul li a:hover::before {
    color: #fff;
}

#home-page-widget-area .business_point_widget_social ul li a::before {
    color: #AE1715;
}

#home-page-widget-area .business_point_widget_social ul li a:hover::before {
    color: #fff;
}

#home-page-widget-area .business_point_widget_social li a {
    border: 1px solid #AE1715;
}

#home-page-widget-area .business_point_widget_social ul{
    text-align: center;   
}

#home-page-widget-area .business_point_widget_social li{
    float: none;
    display: inline-block;
    text-align: center;
}

#sidebar-primary .widget.business_point_widget_social ul li {
    border-bottom: none;
}

#sidebar-primary .business_point_widget_social li a {
    border: 1px solid #eeeeee;
}

#sidebar-primary .business_point_widget_social ul li a::before{
    color: #8c8c8c;
}

#sidebar-primary .business_point_widget_social ul li a:hover::before{
    color: #fff;
}

.business_point_widget_social ul li a[href*="facebook.com"]::before {
    content: "\f09a";
}

.business_point_widget_social ul li a[href*="twitter.com"]::before {
    content: "\f099";
}

.business_point_widget_social ul li a[href*="linkedin.com"]::before {
    content: "\f0e1";
}

.business_point_widget_social ul li a[href*="plus.google.com"]::before {
    content: "\f0d5";
}

.business_point_widget_social ul li a[href*="youtube.com"]::before {
    content: "\f167";
}

.business_point_widget_social ul li a[href*="dribbble.com"]::before {
    content: "\f17d";
}

.business_point_widget_social ul li a[href*="pinterest.com"]::before {
    content: "\f0d2";
}

.business_point_widget_social ul li a[href*="bitbucket.org"]::before {
    content: "\f171";
}

.business_point_widget_social ul li a[href*="github.com"]::before {
    content: "\f113";
}

.business_point_widget_social ul li a[href*="codepen.io"]::before {
    content: "\f1cb";
}

.business_point_widget_social ul li a[href*="flickr.com"]::before {
    content: "\f16e";
}

.business_point_widget_social ul li a[href$="/feed/"]::before {
    content: "\f09e";
}

.business_point_widget_social ul li a[href*="foursquare.com"]::before {
    content: "\f180";
}

.business_point_widget_social ul li a[href*="instagram.com"]::before {
    content: "\f16d";
}

.business_point_widget_social ul li a[href*="tumblr.com"]::before {
    content: "\f173";
}

.business_point_widget_social ul li a[href*="reddit.com"]::before {
    content: "\f1a1";
}

.business_point_widget_social ul li a[href*="vimeo.com"]::before {
    content: "\f194";
}

.business_point_widget_social ul li a[href*="digg.com"]::before {
    content: "\f1a6";
}

.business_point_widget_social ul li a[href*="twitch.tv"]::before {
    content: "\f1e8";
}

.business_point_widget_social ul li a[href*="stumbleupon.com"]::before {
    content: "\f1a4";
}

.business_point_widget_social ul li a[href*="delicious.com"]::before {
    content: "\f1a5";
}

.business_point_widget_social ul li a[href*="mailto:"]::before {
    content: "\f0e0";
}
.business_point_widget_social ul li a[href*="soundcloud.com"]::before {
    content: "\f1be";
}
.business_point_widget_social ul li a[href*="wordpress.org"]::before {
    content: "\f19a";
}
.business_point_widget_social ul li a[href*="wordpress.com"]::before {
    content: "\f19a";
}

.business_point_widget_social ul li a[href*="jsfiddle.net"]::before {
    content: "\f1cc";
}

.business_point_widget_social ul li a[href*="tripadvisor.com"]::before {
    content: "\f262";
}

.business_point_widget_social ul li a[href*="foursquare.com"]::before {
    content: "\f180";
}

.business_point_widget_social ul li a[href*="angel.co"]::before {
    content: "\f209";
}

.business_point_widget_social ul li a[href*="slack.com"]::before {
    content: "\f198";
}

/*social links hover effect */

.business_point_widget_social ul li a[href*="facebook.com"]:hover {
    background-color: #3b5998;
}

.business_point_widget_social ul li a[href*="twitter.com"]:hover {
    background-color: #00aced;
}

.business_point_widget_social ul li a[href*="plus.google.com"]:hover {
    background-color: #dd4b39;
}

.business_point_widget_social ul li a[href*="/feed/"]:hover  {
    background-color: #dc622c;
}

.business_point_widget_social ul li a[href*="wordpress.org"]:hover,
.business_point_widget_social ul li a[href*="wordpress.com"]:hover {
    background-color: #45bbe6;
}

.business_point_widget_social ul li a[href*="github.com"]:hover {
    background-color: #4183c4;
}

.business_point_widget_social ul li a[href*="linkedin.com"]:hover {
    background-color: #007bb6;
}

.business_point_widget_social ul li a[href*="pinterest.com"]:hover {
    background-color: #cb2027;
}

.business_point_widget_social ul li a[href*="flickr.com"]:hover {
    background-color: #ff0084;
}

.business_point_widget_social ul li a[href*="vimeo.com"]:hover {
    background-color: #aad450;
}

.business_point_widget_social ul li a[href*="youtube.com"]:hover {
    background-color: #bb0000;
}

.business_point_widget_social ul li a[href*="instagram.com"]:hover {
    background-color: #517fa4;
}

.business_point_widget_social ul li a[href*="dribbble.com"]:hover {
    background-color: #ea4c89;
}

.business_point_widget_social ul li a[href*="skype.com"]:hover {
    background-color: #12a5f4;
}

.business_point_widget_social ul li a[href*="digg.com"]:hover {
    background-color: #333;
}

.business_point_widget_social ul li a[href*="codepen.io"]:hover {
    background-color: #000;
}

.business_point_widget_social ul li a[href*="reddit.com"]:hover {
    background-color: #ff4500;
}

.business_point_widget_social ul li a[href*="mailto:"]:hover {
    background-color: #1d62f0;
}

.business_point_widget_social ul li a[href*="foursquare.com"]:hover {
    background-color: #f94877;
}

.business_point_widget_social ul li a[href*="stumbleupon.com"]:hover {
    background-color: #eb4924;
}

.business_point_widget_social ul li a[href*="twitch.tv"]:hover {
    background-color: #6441a5;
}

.business_point_widget_social ul li a[href*="tumblr.com"]:hover {
    background-color: #32506d;
}

.business_point_widget_social ul li a[href*="foursquare.com"]:hover {
    background-color: #f94877;
}

.business_point_widget_social ul li a[href*="stumbleupon.com"]:hover {
    background-color: #eb4924;
}

.business_point_widget_social ul li a[href*="twitch.tv"]:hover {
    background-color: #6441a5;
}

.business_point_widget_social ul li a[href*="tumblr.com"]:hover {
    background-color: #32506d;
}

.business_point_widget_social ul li a[href*="soundcloud.com"]:hover {
    background-color: #ff5500;
}

.business_point_widget_social ul li a[href*="wordpress.org"]:hover {
    background-color:#45bbe6;
}

.business_point_widget_social ul li a[href*="jsfiddle.net"]:hover {
    background-color:#4679bd;
}

.business_point_widget_social ul li a[href*="tripadvisor.com"]:hover {
    background-color:#86c171;
}

.business_point_widget_social ul li a[href*="foursquare.com"]:hover {
    background-color:#2d5be3;
}

.business_point_widget_social ul li a[href*="angel.co"]:hover {
    background-color:#000;
}

.business_point_widget_social ul li a[href*="slack.com"]:hover {
    background-color:#56b68b;
}


/*--------------------------------------------------------------
## Top header Style
--------------------------------------------------------------*/

.top-header {
    float: left;
    width: 100%;
}

.top-header {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #AE1715;
}

.top-left {
    float: left;
}

.top-right {
    float: right;
}

.top-left span {
    color: #fff;
    margin-right: 15px;
    line-height: 1;
    font-size: 14px;
}

.top-left span i {
    color: #fff;
    margin-right: 10px;
}

.top-menu-content .menu {
    float: left;
    list-style: none;
    margin:0;
}

.top-menu-content .menu li {
    display: inline-block;
    line-height: 1;
}

.top-menu-content .menu li a{
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    z-index: 99;
    text-transform: capitalize;
    padding: 0px 15px;
    line-height: 1;
    position: relative;
}

.top-header .business_point_widget_social li {
    margin:0;
}

.top-header .business_point_widget_social li a {
    height: auto;
    width: auto;
    border:0;
    line-height: 1;
    padding: 0px 15px;
    position: relative;
    transform: none !important;
}

.top-header .business_point_widget_social ul li a::before {
    color: #fff;
    font-size: 14px;
    line-height: 1;
    transform: none !important;
}

.top-header .business_point_widget_social li a:hover {
    background: transparent !important;
    border:0px;
}

.top-menu-content .menu li a:after,
.top-header .business_point_widget_social li a:after {
    content:"";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: #fff;

    -webkit-transform: rotate(35deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
      -ms-transform: rotate(35deg); /* IE 9 */
          transform: rotate(35deg); /* IE 10, Fx 16+, Op 12.1+ */
}

.top-menu-content .menu li:last-child a:after ,
.top-header .business_point_widget_social li:last-child a:after{
    display: none;
}


/*--------------------------------------------------------------
## Main slider Style
--------------------------------------------------------------*/

#featured-slider,
#main-slider .cycle-slide img {
    float: left;
    width: 100%;
}


#featured-slider {
    overflow: hidden;
    position: relative;
    z-index: 99;
}

.overlay-enabled .cycle-slide{
    position: relative;
}

.overlay-enabled .cycle-slide:before{
    background: rgba(0,0,0,0.45);
    content:"";
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left:0;
}

#main-slider {
    color: #686868;
    clear: both;
    position: relative;
    text-align: center;

}

#main-slider article {
    width: 100%;
}

#main-slider.cycle-slide {
    background-position: center;
    background-size: cover;
    overflow: hidden;
    position: relative; 
    display:table;
    width:100%;
    z-index:5;
    padding-top:130px;
    padding-bottom: 130px;
}

#main-slider .cycle-caption{
    width:50%;
    text-align: left;
    z-index: 999;
    transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    position: absolute;
    top: 50%;
}

#main-slider .cycle-slide .cycle-caption{

    opacity:0;
    display:table-cell;
    vertical-align:middle;
    width:100%;
    text-align: left;
}

#main-slider .cycle-slide .cycle-caption .caption-wrap {
    width: 100%;
    text-align: center;
} 

#main-slider .cycle-slide .cycle-caption .caption-wrap p{
    margin-bottom: 0px;
    font-size: 15px;
}

#main-slider  .cycle-slide.cycle-slide-active .cycle-caption {
    opacity:1;
    
}

#main-slider .cycle-caption h1,
#main-slider .cycle-caption h2,
#main-slider .cycle-caption h3{
    color: #fff;
    display: inline-block;
    font-family: "Open Sans",sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 1px;
    margin-bottom: 10px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    position:relative;
    width:100%;

}

#main-slider .cycle-caption .slider-meta {
    font-size: 17px;
    margin-bottom: 0px;
    color: #fff;
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    max-width: 100%;
}

.slider-cta {
    padding-top: 50px;
}

#main-slider .cycle-slide .cycle-caption .caption-wrap .button {
    margin-top: 25px;
}

/*slider pager box*/
#main-slider .pager-box {
    background-color: #AE1715;
    cursor: pointer;
    display: inline-block;
    float: none;
    height: 10px;
    margin: 3px 5px;
    width: 10px;
    z-index: 99;
    
}

#main-slider .cycle-pager {
    bottom: 5%;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%;
    z-index: 999;
}

#main-slider .pager-box.cycle-pager-active {
    background-color: #fff;
    height: 14px;
    width: 14px;
    border:5px solid #AE1715;
}

#main-slider .pager-box,
#main-slider .pager-box.cycle-pager-active {
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
} 

/*Slider cycle next / previous*/

#main-slider .cycle-prev,
#main-slider .cycle-next {
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-weight: normal;
    position: absolute;
    transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    position: absolute;
    top: 50%;
    text-align: center;
    width: auto;
    height: auto;
    z-index: 99999;
    /*line-height:1.3 ;*/
    background-color: transparent;
    opacity:0;
}

#main-slider .cycle-prev {
    left: -30px;
}

#main-slider .cycle-next {
    right: -30px;
}

#main-slider .cycle-prev i,
#main-slider .cycle-next i{
    font-size:70px;
    color:#fff;
    line-height:30px;
}

#main-slider .cycle-prev:hover,
#main-slider .cycle-next:hover{
   color: #fff;
}

#main-slider:hover .cycle-prev,
#main-slider:hover .cycle-next {
    opacity:1;
}

#main-slider:hover .cycle-prev {
    left: 30px;
}

#main-slider:hover .cycle-next {
    right: 30px;
}

#main-slider .cycle-prev:hover i{
    color: #AE1715;
}

#main-slider .cycle-next:hover i{
    color: #AE1715;
}

#main-slider:hover .cycle-prev,
#main-slider:hover .cycle-next,
#main-slider .cycle-prev,
#main-slider .cycle-next {
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}


/*--------------------------------------------------------------
## Main Content Style
--------------------------------------------------------------*/

#primary {
    width: 100%;
    padding-left:15px;
    padding-right: 15px;
    float: left;
}

#content {
    padding: 40px 0 40px 0px;
    clear: both;
}

.search-results .page-title {
    text-align: left;
}

.home-widgets-disabled #content {
    display: none;
}

.home.page-template-home #content,
.page-template-home #content {
    padding: 0;
}

#featured-content {
    padding: 40px 0 0;
    clear: both;
}

#primary article {
    float: left;
    width: 100%;
}

.search-results #primary article {
    text-align: left;
}

.search-results #primary article .entry-title:after{
    display: none;
}

.search-results #primary article .entry-title {
    font-size: 20px;
    font-weight: 400;
}

.search-results #primary article .entry-summary,
.search-results #primary article .entry-content,
.search-results #primary article .entry-meta,
.search-results #primary article .entry-footer {
    font-size: 14px;
}

.home.home-content-not-enabled #content {
    padding: 0;
}

#featured-content{
    float: left;
    width: 100%;
}

.entry-title {
    padding-bottom: 15px;
}

h1.page-title {
    /*background: #f9f9f9 none repeat scroll 0 0;*/
    border-bottom: 1px solid #ddd;
    color: #121212;
    padding: 0 0 15px;
    text-align: left;
}

#primary article .entry-meta .cat-links:before {
    display: none;
}

/*--------------------------------------------------------------
## Sidebar Style
--------------------------------------------------------------*/
.sidebar {
    width:30%;
    padding-left:15px;
    padding-right: 15px;
}

.sidebar .widget-title {
    padding-bottom: 15px;
}

#sidebar-primary {
    float: right;
}

.sidebar a.read-more {
    font-size: 13px;
}

.sidebar a.read-more::before {
    font-size: 10px;
}

#sidebar-secondary {
    float: left;
}

.sidebar .widget {
    padding: 20px;
    border:1px solid #ddd;
    margin: 0 0 30px;
}

.widget-area .widget::after,
.widget-area .widget::before {
    clear: both;
    content: "";
    display: table;
}

.widget-area ul {
    list-style: outside none none;
    padding: 0;
    margin: 0;
}

.widget-area ul ul {
    border-bottom:none;
    padding-bottom: 0;
}

.widget-area ul li {
    padding-bottom: 5px;
    padding-left: 20px;
    padding-top: 5px;
    position: relative;
}

.sidebar a {
    color: #121212;
}
.widget-area ul ul ul ul ul > li {
    padding-left: 0;
}

.widget-area ul ul ul ul ul > li::before {
    left:-20px;
}
.sidebar .search-form input.search-field {
    padding: 12px 4% 11px 10px;
    width: 100%;
    margin: 0;
    height: auto;
    box-sizing: border-box;
    line-height: 1;
    border-radius: 0px;
     -webkit-appearance: none;
  -webkit-border-radius: 0;
}
.sidebar .search-field {
    float: left;
    margin-right: 5px;
    width: 57%;
}

.widget_calendar caption {
    background: #AE1715 none repeat scroll 0 0;
    color: #ffffff;
    padding: 5px;
}

.sidebar .widget-title, .entry-title {
    border-bottom: 3px double;
    padding-bottom: 15px;
}

/*--------------------------------------------------------------
## Footer Style
--------------------------------------------------------------*/

#colophon {
    background-color: #303133;
    clear: both;
    color: #ffffff;
    padding: 15px 0;
    text-align: center;
}

#colophon a {
    color:#fff;
}

#colophon a:hover {
    color: #ccc;
}

#colophon .copyright {
    margin-top: 5x;
    margin-bottom: 1px;
}

#colophon .copyright p {
    font-size: 12px;
}

#footer-widgets {
    background-color: #262626;
    overflow: hidden;
    padding: 53px 0;
}

#footer-widgets .widget-column .container {
    width: 100%;
}

#footer-widgets .widget-title {
    color: #AE1715;
    font-size: 18px;
    font-weight:400;
    margin-bottom: 7px;
    padding-bottom: 10px;
    position: relative;
}

/*#footer-widgets h3.widget-title::after {
    background-color: #AE1715 ;
    bottom: -3px;
    content: "";
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    width: 85px;
}*/

#footer-widgets ul {
    list-style: none;
    margin: 0;
}

#footer-widgets a,
#footer-widgets .textwidget p {
    color: #6e6e6e;
    font-size: 15px;
}

#footer-widgets ul li {
    padding: 2px 0 3px 0px;
    position: relative;
}
.widget-area ul li::before {

    top: 1px;
}
#footer-widgets .widget-column {
    float: left;
    padding: 0 15px;
    width: 25%;
}

#footer-widgets .footer-active-1 {
    width: 100%;
}

#footer-widgets .footer-active-2 {
    width: 50%;
}

#footer-widgets .footer-active-3 {
    width: 33.33%;
}

#footer-widgets .footer-active-4 {
    width: 25%;
}

.widget-column .widget:first-child {
    margin-top: 0;
}

.widget-column .widget {
    margin-top: 15px;
}

#footer-widgets .widget.business_point_widget_social {
    margin-top: 0px;
}

/*Footer Navigation*/

#footer-navigation {
    float: left;
}

#footer-navigation li {
    display: inline-block;
    list-style: outside none none;
}

#colophon .copyright,
#colophon .copyright span,
#colophon .copyright a,
#colophon .site-info,
#colophon .site-info a {
    color: #6e6e6e;
    font-size: 15px;
    line-height: 1;
}

#footer-navigation li a {
    display: block;
    padding: 5px 0;
    margin-right: 15px;
}

#footer-navigation ul {
    margin: 0;
}

/*Scrollup Style*/

.scrollup {
    background-color: #AE1715;
    border-radius: 0;
    bottom: 50px;
    color: #fff;
    display: none;
    float: right;
    line-height: 1.2;
    padding: 1px 10px 4px;
    position: fixed;
    right: 32px;
    text-align: center;
    z-index: 99999;
    transition: all 0.5s ease-in-out 0s;

    -webkit-transform: rotate(45deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
      -ms-transform: rotate(45deg); /* IE 9 */
          transform: rotate(45deg); /* IE 10, Fx 16+, Op 12.1+ */
}

.scrollup:hover {
    background-color: #AE1715;
}

.scrollup i {
    font-size: 38px;
    color: #fff;

    -webkit-transform: rotate(-45deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
      -ms-transform: rotate(-45deg); /* IE 9 */
          transform: rotate(-45deg); /* IE 10, Fx 16+, Op 12.1+ */
}

/*--------------------------------------------------------------
##  layout sidebar
--------------------------------------------------------------*/

.global-layout-left-sidebar #primary {
    float: right;
}

.three-columns-enabled #primary {
    display: inline-block;
    float: none;
    width: 50%;
}

.global-layout-three-columns #sidebar-primary {
    float: right;
    width: 25%;
}

.global-layout-no-sidebar #primary{
    width: 100%;
}

/*--------------------------------------------------------------
## Inner Pages Style
--------------------------------------------------------------*/

/*Basic Breadcrumb */

#breadcrumb {
    float: left;
    width: 100%;
}

#breadcrumb {
    background: #AE1715;
    padding: 30px 0;
    color: #fff;
}
#breadcrumb a {
    color: #dddddd;
}
#breadcrumb .container > div {
    overflow: hidden;
}
.breadcrumb-trail li {
    display: block;
    float: left;
    list-style: outside none none;
    padding-right: 10px;
}
.breadcrumb-trail li.trail-item.trail-end::after {
    display: none;
}
.breadcrumb-trail li::after {
    content: "/";
    margin-left: 10px;
}
.breadcrumb-trail  .trail-items {
    margin: 0;
    padding: 0;
}
/*Error 404 Page*/

section.error-404 {
    text-align: center;
}

#quick-links-404 ul li {
    display: inline-block;
    list-style: outside none none;
}

#quick-links-404 ul li a {
    color: #404040;
    display: block;
    padding: 5px 10px;
}

.error404 #primary {
    width: 100%;
}

/*contact form 7*/

.wpcf7-form {
   /* max-width: 875px; */
   width:100%;
}

div.wpcf7 {
    margin: 0 auto;
    padding: 0;
}

.wpcf7 input {
    width: 100%;
}

.wpcf7 input.wpcf7-submit {
    width: auto;
}

/*--------------------------------------------------------------
## Extra css for Pro
--------------------------------------------------------------*/

/* Global layout sidebar */

#home-page-widget-area {
    padding: 0px;
    clear: both;
}

#home-page-widget-area  .widget {
    margin: 0px;
    padding: 80px 0;
}

.business_point_widget_featured_page img {
    border: 1px solid #ddd;
    padding: 5px;
}
/*--------------------------------------------------------------
## service widget / page css
--------------------------------------------------------------*/
.business_point_widget_services .services-item {
    display: inline-block;
    padding: 15px;
    transition: background-color 0.5s ease-out 0s;
    text-align: center;
    vertical-align: top;
    margin-left: -3px;
    width: 33.33%;
    margin-bottom: 15px;
}

.business_point_widget_services .services-item .service-icon {
    background: #fff;
    border:1px solid #AE1715 ;
    text-align: center;
    width: 68px;
    height: 68px;
    display: inline-block;
    margin-bottom: 20px;
    -webkit-transform: rotate(45deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
      -ms-transform: rotate(45deg); /* IE 9 */
          transform: rotate(45deg); /* IE 10, Fx 16+, Op 12.1+ */
}

.business_point_widget_services .services-item i {
    color: #AE1715;
    display: inline-block;
    font-size: 27px;
    line-height: 68px;
    text-align: center;

    -webkit-transform: rotate(-45deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
      -ms-transform: rotate(-45deg); /* IE 9 */
          transform: rotate(-45deg); /* IE 10, Fx 16+, Op 12.1+ */
}

.business_point_widget_services .services-item:hover .service-icon {
    background: #AE1715; 
}

.business_point_widget_services .services-item:hover .service-icon i {
    color: #fff;
}

.business_point_widget_services .services-item .service-icon,
.business_point_widget_services .services-item i,
.business_point_widget_services .services-item:hover .service-icon,
.business_point_widget_services .services-item:hover .service-icon i {
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}


.services-item-title {
    display: block;
    color: #303133;
    font-weight: 400;
    padding: 11px 0;
    font-size: 18px;
    margin-bottom: 0px;
    text-align: center;
    clear: both;
}
.services-item > p {
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.services-item a {
    color: #AE1715;
    display: inline-block;
    font-size: 15px;
}

.services-item a:after {
    color: #AE1715;
    content:"\f101";
    display: inline-block;
    font-size: 15px;
    font-family: "FontAwesome";
    margin-left: 10px;
}

.business_point_widget_services .services-column-4 .services-item {
    width: 25%;
}

.business_point_widget_services .services-column-3 .services-item {
    width: 33.33%;
}

.business_point_widget_services .services-column-2 .services-item {
    width: 50%;
}

.business_point_widget_services .services-column-1 .services-item {
    width: 100%;
}



/*--------------------------------------------------------------
## call to action widgets style
--------------------------------------------------------------*/
#home-page-widget-area .business_point_widget_call_to_action .widget-title,
#home-page-widget-area .business_point_widget_call_to_action .call-to-action-content p{
    color: #fff;
}

#home-page-widget-area .business_point_widget_call_to_action .call-to-action-content p {
    display: inline-block;
    width: 60%;
}

.business_point_widget_call_to_action {
    background: #f9f9f9 none repeat scroll 0 0;
    padding: 20px 30px;
    position: relative;
    text-align: center;
    z-index: 999;
}

.bg_enabled.business_point_widget_call_to_action::before {
    background: rgba(37, 38, 39, 0.78);
    content: "";
    display: block!important;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    opacity: 0.7;
    left: 0;
}

.bg_enabled.business_point_widget_call_to_action {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}


#home-page-widget-area .business_point_widget_call_to_action .widget-title {
    border: medium none;
    margin-bottom: 20px;
}


a.button {
    color: #ffffff;
    display: inline-block;
}

.sidebar .business_point_widget_call_to_action,
.widget-column .business_point_widget_call_to_action {
    padding: 5px;
}

.business_point_widget_call_to_action .call-to-action-buttons {
	margin-top:30px;
}

.business_point_widget_call_to_action .call-to-action-buttons .button {
    margin-left: 10px;
    margin-right: 10px
}

.business_point_widget_call_to_action .call-to-action-buttons .cta-button-secondary.button {
    background: transparent;
}

.business_point_widget_call_to_action .call-to-action-buttons .cta-button-secondary.button:hover {
    background: #AE1715;
}

/*--------------------------------------------------------------
## some facts widgets style
--------------------------------------------------------------*/
.bg_enabled.business_point_widget_facts::before {
    background: rgba(37, 38, 39, 0.78);
    content: "";
    display: block!important;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    opacity: 0.7;
    left: 0;
}

.bg_enabled.business_point_widget_facts {
    background: #AE1715 none repeat scroll 0 0;
    padding: 75px 0px;
    text-align: center;
    z-index: 999;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}


#home-page-widget-area .business_point_widget_facts .widget-title {
    border: medium none;
    color: #fff;
}

.business_point_widget_facts .fact-full .counter-item {
    width: 100%;
    float: left;
}

.business_point_widget_facts .fact-half .counter-item {
    width: 50%;
    float: left;
}

.business_point_widget_facts .fact-third .counter-item {
    width: 33%;
    float: left;
}

.business_point_widget_facts .fact-fourth .counter-item {
    width: 25%;
    float: left;
}

.business_point_widget_facts .counter-item span.count-icon {
    width: 100%;
    display: block;
    text-align: center;
}

.business_point_widget_facts .counter-item span.count-icon i {
    color: #fff;
    font-size: 40px;
}

.business_point_widget_facts .counter-item span.count {
    color: #fff;
    display: block;
    font-size: 30px;
    text-align: center;
    margin: 18px 0 14px 0;
    line-height: 24px;
}

.business_point_widget_facts .counter-item span.count-text {
    color: #AE1715;
    display: block;
    font-size: 18px;
    text-align: center;
}

/*--------------------------------------------------------------
## Our Team widgets style
--------------------------------------------------------------*/

.pt-team-item {
    text-align: center;
}

.pt-team-item .pt-team-title {
    color: #AE1715;
    font-size: 18px;
    font-weight: 400;
}

.pt-team-items-wrap {
    margin-left: 0px;
    margin-right: 0px;
}

.pt-team-item .pt-team-content-wrap {
    position: relative;
}

.pt-team-item  .pt-team-social {
    position: absolute;
    top:-30px;
    margin-top: 0px;
    opacity: 0;
}

.pt-team-item:hover .pt-team-social {
    top:-50px;
    opacity: 1;
}

.widget-area ul.pt-team-social li,
ul.pt-team-social li {
    margin-right:15px; 
    padding:0;
}

.widget-area ul.pt-team-social li:last-child,
ul.pt-team-social li:last-child {
    margin-right: 0px;
}

.pt-team-social li a {
    background: #fff;
    -webkit-transform: rotate(45deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
      -ms-transform: rotate(45deg); /* IE 9 */
          transform: rotate(45deg); /* IE 10, Fx 16+, Op 12.1+ */
}

.pt-team-social li a::before{
    color: #AE1715;
    -webkit-transform: rotate(-45deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
      -ms-transform: rotate(-45deg); /* IE 9 */
          transform: rotate(-45deg); /* IE 10, Fx 16+, Op 12.1+ */
}

.pt-team-item  .pt-team-social,
.pt-team-item:hover .pt-team-social {
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

/*--------------------------------------------------------------
# Portfolio widgets style
--------------------------------------------------------------*/

.pt_theme_addon_widget_portfolio {
	width: 100%;
}

.pt-portfolio-item-wrap {
    margin-left: 0px;
    margin-right: 0px;
}

.pt-portfolio .filter-list {
    text-align: left;
    margin-bottom: 30px;
}

.home .pt-portfolio .filter-list {
    text-align: center;
}

.widget-area .pt-portfolio ul.filter-list li {
    padding:0;
}

.widget-area .pt-portfolio ul.filter-list li:first-child {
    margin-left: 0px;
}

.widget-area ul.filter-list li {
    display: inline-block;
}

.pt-portfolio-item .pt-portfolio-text-wrap .pt-portfolio-title {
    color: #AE1715;
    font-size: 18px;
    font-weight: 400;
}

/*--------------------------------------------------------------
# Testimonial widgets style
--------------------------------------------------------------*/

.pt_theme_addon_widget_testimonials {
    background: #ededed;
    float: left;
    width: 100%;
}

.pt-testimonials .pt-testimonial-item:focus {
    outline:none;
}

.pt-testimonials .slick-prev,
.pt-testimonials .slick-next{
    font-size: 0;
    padding: 0px;
    top:0;
    display: none !important;
} 

.pt-testimonials .slick-prev:before,
.pt-testimonials .slick-next:before {
    font-size: 20px;
    font-family: "FontAwesome";
    color: #fff;
    line-height: 35px;
}

.pt-testimonials .slick-prev:before {
    content:"\f104";
}

.pt-testimonials .slick-next:before {
    content:"\f105"
}

.pt-testimonials {
    text-align: center;
}

.pt-testimonials .pt-testimonial-item-wrap {
    width: 70%;
    display: inline-block;
    float: none;
}

.pt-testimonial-item .pt-testimonial-title {
    font-size: 18px;
    color: #AE1715;
    font-weight: 400;
}

.pt-testimonial-item figure{
    overflow: hidden;
     margin-top:10px;
     margin-bottom: 20px;
}

.slick-dots,
.slick-dots li {
    display: inline-block;
}

.slick-dots li button {
    background: #AE1715;
    padding:0;
    font-size: 0;
    height: 10px;
    width: 10px;
}

.widget-area ul.slick-dots li {
    padding:0;
    margin:3px 5px;
}

.slick-dots li.slick-active button{
    background: #fff;
    height: 14px;
    width: 14px;
    border:5px solid #AE1715;
}

.page-template-default.page .pt-testimonials .pt-testimonial-item-wrap,
.page-template-full-width-page .pt-testimonials .pt-testimonial-item-wrap,
.page-template-no-border-page .pt-testimonials .pt-testimonial-item-wrap{
    width: 100%;
}

.page-template-default.page .pt-testimonials,
.page-template-full-width-page .pt-testimonials,
.page-template-no-border-page .pt-testimonials {
    text-align: left;
}

.page-template-default.page .pt-testimonials .pt-testimonial-item,
.page-template-full-width-page .pt-testimonials .pt-testimonial-item,
.page-template-no-border-page .pt-testimonials  .pt-testimonial-item{
    width: 50%;
    float: none;
    display: inline-block;
    margin-left: -3px;
    vertical-align: top;
    padding-left: 15px;
    padding-right: 15px;
}

.page-template-full-width-page .pt-testimonials .pt-testimonial-item,
.page-template-no-border-page .pt-testimonials  .pt-testimonial-item {
    width: 33.33%;
}

.page-template-default.page .pt-testimonials .pt-testimonial-item .pt-testimonial-caption,
.page-template-full-width-page .pt-testimonials .pt-testimonial-item .pt-testimonial-caption,
.page-template-no-border-page .pt-testimonials  .pt-testimonial-item .pt-testimonial-caption {
    padding-top: 20px;
    border-top:1px solid #ddd;
}

.page-template-default.page .pt-testimonials .pt-testimonial-item:first-child .pt-testimonial-caption,
.page-template-default.page .pt-testimonials .pt-testimonial-item:nth-child(2) .pt-testimonial-caption,
.page-template-full-width-page .pt-testimonials .pt-testimonial-item:first-child .pt-testimonial-caption,
.page-template-full-width-page .pt-testimonials .pt-testimonial-item:nth-child(2) .pt-testimonial-caption,
.page-template-full-width-page .pt-testimonials .pt-testimonial-item:nth-child(3) .pt-testimonial-caption,
.page-template-no-border-page .pt-testimonials .pt-testimonial-item:first-child .pt-testimonial-caption,
.page-template-no-border-page .pt-testimonials .pt-testimonial-item:nth-child(2) .pt-testimonial-caption,
.page-template-no-border-page .pt-testimonials .pt-testimonial-item:nth-child(3) .pt-testimonial-caption {
    padding-top: 0px;
    border-top:0px;
}


/*--------------------------------------------------------------
# Features widgets style
--------------------------------------------------------------*/
.business_point_widget_features{
    background: #AE1715 none repeat scroll 0 0;
    padding: 100px 0 30px 0;
    z-index: 999;
    margin: 0!important;
}

#home-page-widget-area .business_point_widget_features .widget-title{
    color: #fff;
}

#home-page-widget-area .business_point_widget_features .widget-title span::before,
#home-page-widget-area .business_point_widget_features .widget-title span::after {
    background-color: #fff;
}

#home-page-widget-area .business_point_widget_features .feature-image {
    float: left;
    padding: 0 50px 10px 0;
    width: 50%;

}

#home-page-widget-area .business_point_widget_features .feature-image img {
    float: right;
}

#home-page-widget-area .business_point_widget_features .features-list {
    float: right;
    padding: 0 0 10px 20px;
    width: 50%;
}

#home-page-widget-area .business_point_widget_features .features-list .features-item {
    clear: both;
    display: block;
    float: left;
    width: 100%;
    padding-bottom: 20px;
}

#home-page-widget-area .business_point_widget_features .features-list .features-icon{
    float: left;
    width: 15%;
}

#home-page-widget-area .business_point_widget_features .features-list .features-icon i{
    font-size: 40px;
    color: #fff;
    line-height: 75px;
    width: 75px;
    height: 75px;
    text-align: center;
}

#home-page-widget-area .business_point_widget_features .features-list .features-detail{
    float: left;
    width: 85%;
}

#home-page-widget-area .business_point_widget_features .features-list .features-detail h3,
#home-page-widget-area .business_point_widget_features .features-list .features-detail p{
    color: #fff;
    margin-bottom: 2px;
}

/*--------------------------------------------------------------
### Advaneced recent post widget style
--------------------------------------------------------------*/

.business_point_widget_advanced_recent_posts .advanced-recent-posts-item {
    clear: both;
    margin-top: 20px;
    float: left;
    width: 100%;
}

.business_point_widget_advanced_recent_posts .advanced-recent-posts-item:first-child {
    margin: 0;
}

.business_point_widget_advanced_recent_posts .advanced-recent-posts-title {
    font-size: 15px;
    margin-bottom:0;
}

.business_point_widget_advanced_recent_posts .advanced-recent-posts-date {
    font-style: italic;
    display: block;
    margin-bottom: 3px;
    font-size: 13px;
}

#home-page-widget-area::after,
#home-page-widget-area::before,
#home-page-widget-area .widget::after,
#home-page-widget-area .widget::before {
    display: table;
    clear: both;
    content: "";
}

#footer-widgets .advanced-recent-posts-item {
    border-top: 1px solid #3c3c3c;
    margin-left: 0;
    margin-right: 0;
    margin-top: 5px;
    padding-top: 5px;
}

#footer-widgets .advanced-recent-posts-item:first-child {
    margin: 0;
    padding: 0;
    border: none;
}

.widget_tag_cloud .tagcloud a {
    font-size: 14px!important;
    border: 1px solid #bababa;
    padding: 5px 10px;
    display: inline-block;
    margin-bottom: 5px;
    line-height: 22px;
}

/*--------------------------------------------------------------
### Latest News post widget style
--------------------------------------------------------------*/

.business_point_widget_latest_news{
    padding-bottom: 50px;
    float: left;
    width: 100%;
}
.business_point_widget_latest_news .latest-news-item {
    display: inline-block;
    padding: 0 15px;
    margin-bottom: 15px;
    vertical-align: top;
    margin-left: -3px!important;
    width: 50%;
}

.business_point_widget_latest_news .small-items-wrap.layout-style-two .latest-news-item{
    width: 33.33%;
}

.business_point_widget_latest_news .latest-news-item img {
    margin: 0;
}

.business_point_widget_latest_news .latest-news-title > a {
    color: #fff;
	float:left;
	width:100%;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0px;
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.business_point_widget_latest_news .latest-news-wrapper {
    position: relative;
    transition: all 0.5s ease 0s;
}

.business_point_widget_latest_news .latest-news-wrapper::after {
    bottom: -44px;
    content: "";
    display: block;
    height: 44px;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 99;

}

.business_point_widget_latest_news .latest-news-wrapper:hover::after {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}


a.read-more {
    background: #AE1715 none repeat scroll 0 0;
    color: #ffffff;
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    padding: 8px;
    text-align: center;
}



.business_point_widget_latest_news .latest-news-thumb {
    overflow: hidden;
	margin-bottom:0px;
	position:relative;
}

.business_point_widget_latest_news .latest-news-thumb:after {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+4,000000+91&0+29,0.9+100 */
background: -moz-linear-gradient(top, rgba(255,255,255,0) 4%, rgba(182,182,182,0) 29%, rgba(0,0,0,0.79) 91%, rgba(0,0,0,0.9) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(255,255,255,0) 4%,rgba(182,182,182,0) 29%,rgba(0,0,0,0.79) 91%,rgba(0,0,0,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(255,255,255,0) 4%,rgba(182,182,182,0) 29%,rgba(0,0,0,0.79) 91%,rgba(0,0,0,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#e6000000',GradientType=0 ); /* IE6-9 */
	content:"";
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}


.business_point_widget_latest_news .latest-news-item img {
    vertical-align: middle;
    margin-bottom: 0;
    width: 100%;
}

.business_point_widget_latest_news .latest-news-title {
    margin-bottom:5px;
	float: left;
    width: 100%;
}

.business_point_widget_latest_news .latest-news-col-2 .latest-news-item {
    width: 50%;
}

.business_point_widget_latest_news .latest-news-col-3 .latest-news-item {
    width: 33.33%;
}

.business_point_widget_latest_news .latest-news-col-4 .latest-news-item {
    width: 25%;
}

.business_point_widget_latest_news .latest-news-meta > span {
    font-size: 12px;
}

.business_point_widget_latest_news .latest-news-meta .latest-news-comments {
    float: right;
}

.business_point_widget_latest_news .latest-news-col-3 .latest-news-meta > span {
    font-size: 15px;
}

.business_point_widget_latest_news .latest-news-meta > span::before {
    content: "";
    display: inline-block;
    float: left;
    font-family: FontAwesome;
    font-size: 12px;
    height: auto;
    line-height: 1.7;
    margin-right: 7px;
}

.business_point_widget_latest_news .latest-news-meta > span.latest-news-date::before {
    content: "\f073";
}

.business_point_widget_latest_news .latest-news-meta > span.latest-news-comments::before {
    content: "\f086";
}

.business_point_widget_latest_news .large-item {
    float: left;
    width: 33.33%;
}

.business_point_widget_latest_news .small-items-wrap {
    float: left;
    width: 66.67%;
}

.business_point_widget_latest_news .small-items-wrap.layout-style-two {
    width: 100%;
}

.latest-news-wrapper {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    position: relative;
}

.business_point_widget_latest_news .large-item .latest-news-item {
    width: 100%;
}

.latest-news-widget .latest-news-item {
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}

.business_point_widget_latest_news .latest-news-thumb a {
    display: inline-block;
}

.business_point_widget_latest_news .latest-news-item  {
	padding-left:15px;
	padding-right:15px;
	margin-bottom:30px;
}

.latest-news-widget .latest-news-wrapper {
    position: relative;
}

.latest-news-widget .latest-news-text-wrap {
    position: absolute;
    bottom: 0;
    padding: 20px;
    bottom: 0px;
    width: 100%;
}

.latest-news-widget .latest-news-text-wrap .latest-news-date {
    float: left;
    width: 100%;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
}


/*--------------------------------------------------------------
## contact widgets style
--------------------------------------------------------------*/
.bg_enabled.business_point_widget_contact::before {
    background: rgba(37, 38, 39, 0.78);
    content: "";
    display: block!important;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    opacity: 0.7;
    left: 0;
}

.business_point_widget_contact{
    background: #AE1715 none repeat scroll 0 0;
    padding: 75px 0px;
    text-align: left;
    z-index: 999;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}


#home-page-widget-area .business_point_widget_contact .widget-title,
.business_point_widget_contact .contact-wrapper form label,
.contact-right p,
.business_point_widget_contact .contact-right ul li h6,
.business_point_widget_contact .contact-right ul li h5,
.business_point_widget_contact .contact-right ul li h4,
.business_point_widget_contact .contact-right ul li i,
.business_point_widget_contact .contact-right ul li a {
	color:#fff;
}

.business_point_widget_contact .contact-right ul li:before {
	display:none;
}

.business_point_widget_contact .contact-right ul li {
	padding-left:0px;
}

.business_point_widget_contact .contact-right ul li h6,
.business_point_widget_contact .contact-right ul li h5,
.business_point_widget_contact .contact-right ul li h4{
	padding-bottom:5px;
	border-bottom:1px solid #fff;
}

.business_point_widget_contact .contact-wrapper form label {
	text-align:left;
}

.business_point_widget_contact .contact-wrapper form input,
.business_point_widget_contact .contact-wrapper form textarea {
	margin-top:5px;
}

.business_point_widget_contact .contact-wrapper form input[type="text"], 
.business_point_widget_contact .contact-wrapper form input[type="email"], 
.business_point_widget_contact .contact-wrapper form input[type="url"], 
.business_point_widget_contact .contact-wrapper form input[type="password"], 
.business_point_widget_contact .contact-wrapper form input[type="search"] {
	padding:10px;
}

.contact-left,
.contact-right {
	float:left;
	width:50%;
}

.contact-left {
	padding-right:15px;
}

.contact-right {
	padding-left:15px;
}

.contact-right iframe {
	float:left;
	width:100%;
	margin-bottom:20px;
}

.business_point_widget_contact .contact-right ul li i {
	margin-right:8px;
}

.business_point_widget_contact .contact-wrapper form input[type="submit"] {
	border:1px solid #fff;
	color:#fff;
	
	-webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

.business_point_widget_contact .contact-wrapper form input[type="submit"]:hover {
	background-color:#fff;
	border-color:#fff;
	color:#AE1715;
	
	-webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

.bg_enabled.business_point_widget_contact .contact-wrapper form input[type="submit"]:hover {
	border-color:#AE1715;
}

#home-page-widget-area .business_point_widget_contact .widget-title span::before,
#home-page-widget-area .business_point_widget_contact .widget-title span::after {
	background-color: #fff;
}

#home-page-widget-area .bg_enabled.business_point_widget_contact .widget-title span::before,
#home-page-widget-area .bg_enabled.business_point_widget_contact .widget-title span::after {
	background-color: #AE1715;
}


/*sidebar and footer widget area style*/

#footer-widgets .business_point_widget_latest_news .latest-news-widget .latest-news-item,
.sidebar .business_point_widget_latest_news .latest-news-widget .latest-news-item{
    margin-bottom: 15px;
    width: 100%;
}
/*--------------------------------------------------------------
### Our Team widget style
--------------------------------------------------------------*/
.business_point_widget_teams .our-team-item {
    float: left;
    padding: 0 15px;
    text-align: center;
    color: #fff;
}

.business_point_widget_teams .thumb-summary-wrap {
    position: relative;
}

.business_point_widget_teams .our-team-summary {
    background:rgba(51, 51, 51, 0.51) none repeat scroll 0 0;
    bottom: 0;
    color: rgb(255, 255, 255);
    display: none;
    padding: 20px 15px 0;
    position: absolute;
}

.business_point_widget_teams .our-team-thumb img {
    width: 100%;
}

.business_point_widget_teams .our-team-text-wrap {
    background:#AE1715 ;
    margin: -10px auto 10px;
    max-width: 100%;
    padding: 5px;
    position: relative;
    z-index: 999;
}

.business_point_widget_teams .our-team-title {
    margin: 0;
}

.business_point_widget_teams .our-team-title > a {
    color: #ffffff;
}

.business_point_widget_teams .team-designation {
    font-size: 18px;
    font-style: italic;
    margin-bottom: 7px;
}

.business_point_widget_teams .our-team-col-1 .our-team-item {
    width: 100%;
}

.business_point_widget_teams .our-team-col-2 .our-team-item {
    width: 50%;
}

.business_point_widget_teams .our-team-col-3 .our-team-item {
    width: 33.33%;
}

.business_point_widget_teams .our-team-col-4 .our-team-item {
    width: 25%;
}

/*--------------------------------------------------------------
### Clients  widget style
--------------------------------------------------------------*/

.pt-clients {
	text-align: center;
}

.pt_theme_addon_widget_clients {
	background: #ededed;
}

/*--------------------------------------------------------------
### Newsletter  widget style
--------------------------------------------------------------*/
.business_point_widget_newsletter,
.newsletter-form,
.newsletter-form form input[type="email"] {
    float: left;
    width: 100%;
}

.newsletter-form {
    text-align: center;
    position: relative;
}

.newsletter-form form {
    width: 60%;
    display: inline-block;
    position: relative;
}

.newsletter-form form input[type="email"] {
    color: #6e6e6e;
    width: 100%;
    padding: 14px 20px;
    line-height: 1;
    font-size: 15px;
    font-weight: 400;
}

.newsletter-form form input[type="submit"] {
    position: absolute;
    right: 0;
    padding: 13px 70px;
    font-size: 16px;
    font-weight: 400;
}

.overlay-none,
.overlay-light,
.overlay-dark {
    position: relative;
}

.overlay-light:before,
.overlay-dark:before {
   content: "";
   position: absolute;
   top:0;
   left:0;
   height: 100%;
   width: 100%;  
   display: block !important;
}

.overlay-light:before {
    background: rgba(255,255,255,0.70);
}

.overlay-dark:before {
   background: rgba(0,0,0,0.45); 
}

.business_point_widget_newsletter .section-title {
    position: relative;
}

.business_point_widget_newsletter.overlay-dark .section-title .widget-title,
.business_point_widget_newsletter.overlay-dark .section-title p{
    color: #fff !important;
} 

.business_point_widget_newsletter.overlay-dark .section-title .seperator span:before, 
.business_point_widget_newsletter.overlay-dark .section-title .seperator span:after {
    background-color: #fff;
}

/* Default background css */
.business_point_widget_facts,
.business_point_widget_call_to_action {
	background:#262626;
}

/* Blog page starts */
.page-template-full-width-page #primary {
    width: 100%;
}

#primary .post,
#primary .page,
#primary .post .entry-head,
#primary .page .entry-head,
.single  #primary .post .content-wrap,
.single  #primary .post .content-wrap .content-wrap-inner{
    float: left;
    width: 100%;
}

#primary .post .content-wrap,
#primary .post .content-wrap .content-wrap-inner,
#primary .page .content-wrap,
#primary .page .content-wrap .content-wrap-inner{
    float: none;
}

#primary .post .content-wrap .content-wrap-inner,
#primary .page .content-wrap .content-wrap-inner {
    background: #fff;
}

.search-results  #primary article .content-wrap .content-wrap-inner {
    box-shadow: none;
    padding: 0px;
    margin-top: 0px;
}

.search-results  #primary article {
    padding: 20px;
    border:1px solid #ddd;
}

#primary .post,
#primary .page {
    margin-bottom: 40px;
}

.page-template-no-border-page #primary .page {
    padding: 0px;
    border:0px;
} 

.page-template-no-border-page #primary {
    width: 100%;
}

#primary .post  .entry-title,
#primary .page .entry-title {
    font-size: 25px;
    letter-spacing: 0px;
    border-bottom: 0px;
    padding-bottom: 0px;
    font-weight: 400;
    margin-bottom: 15px;
}

#primary .post  .entry-title a,
#primary .page .entry-title a{
    color: #303133;
    line-height: 1.2;
}

#primary .post  .entry-title:hover a,
#primary .page .entry-title:hover a{
    color: #AE1715;
}

.single #primary .post  .entry-title {
    font-size: 24px;
    margin: 10px 0;
}


#primary .post .entry-meta,
#primary .post .entry-content,
#primary .post .entry-footer {
    font-size: 14px;
}

#primary .post .entry-footer {
    margin-bottom: 15px;
}

#primary .post .entry-meta {
    margin-bottom: 7px;
}

#primary .post .entry-content p {
    line-height: 1.7;
}

#primary .post .featured-thumb,
#primary .post .featured-thumb a,
#primary .page .featured-thumb,
#primary .page .featured-thumb a,
.search-results  #primary article .featured-thumb,
.search-results  #primary article .featured-thumb a {
    float: left;
    line-height: 1;
}

#primary .post .featured-thumb img,
#primary .page .featured-thumb img,
.search-results  #primary article .featured-thumb img {
    float: left;
    margin-bottom: 15px;
    margin-right: 20px;
}

.single #primary .post .featured-thumb img {
    margin-right: 0px;
}

#primary .post .content-wrap {
    background: #fff;
    text-align: left;
}

#primary .post .content-wrap.content-no-image {
    position: static;
    width: 100%;
    -webkit-transform: none;
       -moz-transform: none;
        -ms-transform: none;
         -o-transform: none;
            transform: none;
}

#primary .post .featured-thumb,
.search-results #primary article  .featured-thumb{
    float: left;
}

#primary  .entry-footer .posted-on::before{
    content: "\f274";
}

#primary  .entry-meta .cat-links a {
    color: #999;
    font-style: normal;
    font-size: 14px;
}

#primary  .entry-footer a {
    color: #999;
    font-style: normal;
    text-transform: capitalize;
}

#sidebar-primary .widget .widget-title,
#primary .page-header .page-title{
    border-bottom: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative;
    display: block;
    width: 100%;
    font-weight: 400;
    border-bottom:2px solid #AE1715;
    line-height: 1;
}
#sidebar-primary .widget .widget-title{
    font-size: 24px;
}
.search-results  #primary article .entry-title {
    margin-bottom: 5px;
}


/*#sidebar-primary .widget .widget-title:after,
#primary .page .entry-title:after,
#primary .page-header .page-title:after{
    content: "";
    float: left;
    width: 50px;
    height: 2px;
    background: #AE1715;
    position: absolute;
    left: 0;
    bottom: -5px;
}*/

#primary .page-header .page-title {
    background: transparent;
    border: 0px;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative;
    display: block;
    width: 100%;
    font-weight: 400;
    border-bottom: 2px solid #AE1715;
    line-height: 1;
}

#primary .page .entry-content ul li i {
    margin-right: 10px;
}

#sidebar-primary .widget ul li {
    padding: 10px 0px;
    border-bottom: 1px dashed #ddd;
}

#sidebar-primary .widget ul li:last-child {
    border-bottom: 0;
}

/*--------------------------------------------------------------
# Post navigation  Styles
--------------------------------------------------------------*/

.post-navigation,
.post-navigation .nav-links,
.post-navigation  .nav-previous,
.post-navigation  .nav-next {
    float: left;
    width: 100%;
}

.post-navigation  .nav-previous,
.post-navigation  .nav-next,
.post-navigation  .nav-previous:hover,
.post-navigation  .nav-next:hover {
    background: transparent;
    width: 50%;
}

.post-navigation  .nav-previous {
    padding-left: 0px;
    text-align: left;
}

.post-navigation  .nav-next {
    padding-right: 0px;
    text-align: right;
    float: right;
}

.post-navigation  .nav-previous a,
.post-navigation  .nav-next a {
    color: #121212;
}

.post-navigation  .nav-previous:hover a,
.post-navigation  .nav-next:hover a,
.post-navigation  .nav-previous:hover:before,
.post-navigation  .nav-next:hover:after{
    color: #AE1715;
}

.post-navigation  .nav-previous:before,
.post-navigation  .nav-next:after{
    color: #AE1715;
}



#footer-widgets ul li {
    padding: 5px 0px;
}

#footer-widgets ul li:last-child {
    border-bottom: 0px;
}

#footer-widgets .business_point_widget_social ul li {
    border-bottom: 0px;
}

#primary  .entry-footer .byline::before {
    content: "\f2c0";
    margin-right: 5px;
}

.single-wrap .featured-thumb {
    max-width: 100% !important;
    width: 100%;
}

.single-wrap .featured-thumb img {
    width: 100%;

}

.single-wrap .single-inner {
    display: block;
    width: 100%;
    padding: 0px;
}

.single-wrap .single-inner-content {
    background: #fff;
    position: relative;
    float: left;
    padding: 20px 0px;
    width: 100%;
}

.single-wrap.single-no-image .single-inner-content {
    margin-top: 0px;
    max-width: 100%;
} 

.single-wrap .single-inner-content .entry-header {
    text-align: left;
}

#primary .single-wrap  .entry-title {
    margin: 10px 0px;
}

blockquote{
    display: block;
    width: 100%;
    border-left: 5px solid #AE1715;
    padding: 5px 20px;
    margin: 15px 0px;
}

blockquote p{
    margin-bottom: 0px;
}

.post-navigation,
.post-navigation .nav-links,
.pagination {
    float: left;
    width: 100%;
}

.post-navigation .nav-links .nav-previous {
    float: left;
}

.post-navigation .nav-links .nav-next {
    float: right;
}

#primary .sticky {
    text-align: center;
}


#primary .sticky .content-wrap.content-no-image {
    margin-top: 0px;
    width: 100% !important;
    border: 0 !important;
}

#primary .sticky .content-wrap.content-with-image {
    border: 0 !important;
}

#primary .sticky .content-wrap .entry-title a:before {
    color: #999;
    content:"\f08d";
    font-size: 20px;
    font-family: "FontAwesome";
    margin-right: 10px;
    transform: rotate(-25deg);
    display: inline-block;
}

/* Blog Page ends */


/*--------------------------------------------------------------
## Recent Post sidebar Starts
--------------------------------------------------------------*/

.bp-advanced-recent-posts .recent-posts-side .news-item {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-left: 95px;
    margin-bottom: 10px;
    vertical-align: top;
}

.bp-advanced-recent-posts .recent-posts-side .news-item:last-child {
    margin-bottom: 0px;
}

.bp-advanced-recent-posts .recent-posts-side .news-item .news-thumb{
    width: 80px;
    position: absolute;
    left: 0;
    top:0;
    line-height: 0;
}

.bp-advanced-recent-posts .recent-posts-side .news-item .news-thumb a {
    float: left;
    line-height: 0;
}

.bp-advanced-recent-posts .recent-posts-side .news-item .news-text-wrap {
   float: left; 
   min-height: 80px;
}

.bp-advanced-recent-posts .recent-posts-side .news-item .news-text-wrap h2 {
    font-size: 18px;
    margin-bottom: 0px;
    font-weight: 400;
}

.bp-advanced-recent-posts .recent-posts-side .news-item .news-text-wrap h2 a{
    color: #303133;
}

/*--------------------------------------------------------------
## Error 404 Page Starts
--------------------------------------------------------------*/
.error-404.not-found,
.error-404.not-found  form.search-form input[type="search"],
.error-404.not-found  form.search-form input[type="text"],
.error-404.not-found .page-header {
    float: left;
    width: 100%;
}

 .error-404.not-found {
    padding: 0px;
 }

 .error-404.not-found  form.search-form {
    position: relative;
    display: inline-block;
    width: 60%;
 }


 .error-404.not-found  form.search-form input[type="search"],
.error-404.not-found  form.search-form input[type="text"]{
    padding: 10px;
    box-sizing: border-box;
    height: auto;
}

.error-404.not-found  form.search-form input[type="submit"],
.search-no-results  form.search-form input[type="submit"]{
    position: absolute;
    right: 0;
    background-color: #AE1715;
    color: #fff; 
    border:0;
    padding: 14px 40px 14px 40px;
    line-height: 1;
    height: auto;
}

.error-404.not-found  form.search-form input[type="submit"]:hover{
    background-color: #AE1715;
    color: #fff;
    border:0;
}

.error404 #primary,
.error-404.not-found .page-header {
    width: 100%;
    text-align: center;
}

.error-404.not-found .page-header .page-title {
    float: none !important;
    display: inline-block !important;
    width: auto !important;
}


/*--------------------------------------------------------------
 Mean Menu Css
--------------------------------------------------------------*/
/*-------------------------------------- 
14.0 Mean Menu  Css Styles
----------------------------------------*/
.mean-container .mean-bar {
    background: transparent;
    box-shadow: none;
    z-index: 999;

}

.mean-container a.meanmenu-reveal span{
    background: #AE1715;
}

.mean-container .mean-nav ul li a  {
    background: #AE1715;
}

.mean-container a.meanmenu-reveal {
    color: #AE1715;
    top: -3px;
}

.mean-container .mean-nav {
    margin-top: 78px;
}

.mean-container .mean-nav ul li a {
    text-shadow: none;
}

.mean-container .mean-nav ul li a.mean-expand {
    z-index: 999;
}

.mean-container .mean-nav ul li a {
    color: #fff !important;
    width: 100%;
}


/*--------------------------------------------------------------
##  Media Queries
--------------------------------------------------------------*/

/* Smaller than standard 1139 (devices and browsers) */
@media only screen and (max-width:1260px) {
    .site-layout-boxed #page,
    .container {
        width: 97%;
    }


    .sidebar .search-form input.search-field {
        height: auto;
        margin: 0;
        width: 100%;
    }

    .sidebar input[type="submit"] {
        display: block;
        float: left;
    }

    .comments-area form#commentform p {
        float: left;
        width: 100%;
    }

    #comments input {
        width: 100%;
    }

    #comments input#submit {
        width: auto;
    }

    #main-slider .cycle-caption {
        visibility: visible;
    }
    #main-slider .cycle-caption {
        opacity: 1;

    }

    .comments-area form#commentform p.comment-form-cookies-consent input[type="checkbox"] {
        width: auto;
    }

    .comments-area form#commentform p.comment-form-cookies-consent label {
        display: block; 
    }
}

@media screen and (max-width: 1170px) {

    #primary .post .featured-thumb {
        max-width: 90%;
    }

    #home-page-widget-area .business_point_widget_call_to_action .call-to-action-content p {
        width: 100%;
    }
}

@media screen and (max-width: 1050px) {

    #main-nav {
        display: none;
    }

     /* Mean MEnu css */

    #main-nav {
        display: none;
    }

    .mean-container .main-navigation ul li.menu-item-has-children > a::after,
    .mean-container .main-navigation ul li.page_item_has_children > a::after {
        display: none;
    }

       .mean-container .mean-nav{
        overflow: hidden;
    }

    .main-navigation ul li ul{
        position: relative;
        top:0;
        left:0 !important;
        opacity: 1;
    }

    .mean-container .mean-nav ul li a.mean-expand {
        z-index: 99999999;
        height: 52px;
        width: 52px;
        line-height:52px;
        color: #fff !important;
        background: transparent;
        box-shadow: none;
        padding: 0 !important;
        border-left: 1px solid rgba(255,255,255,0.5) !important;

    }

    .mean-container .mean-nav ul li a.mean-expand.mean-clicked,
    .mean-container .mean-nav ul li a.mean-expand.mean-clicked:hover,
    .mean-container .mean-nav ul li a.mean-expand:hover  {
        background: transparent;
    }

    .mean-container  .main-navigation ul li.menu-item-has-children > a, 
    .mean-container  .main-navigation ul li.page_item_has_children > a {
        padding-right: 40px;
    }

    .mean-container .mean-nav ul li li a {
        width: 100%;
        opacity: 1;
    }

    .mean-container .mean-nav ul li li li a {
    width: 100%;
    }

    .mean-container .main-navigation ul li.menu-item-has-children ul.sub-menu li a{
    color: #fff;
    }

    .mean-container .mean-nav ul li a:hover {
         background: #AE1715;
    }

    /* Mean Menu css ends */

}

/* Smaller than standard 1024 (devices and browsers) */
@media only screen and (max-width:1023px) {

    h1 {
        font-size: 22px;
    }

    h2 {
        font-size: 20px;
    }

    h3{
        font-size: 18px;
    }

    h4 {
        font-size: 16px;
    }

    h5 {
        font-size: 15px;
    }

    h6 {
        font-size: 14px;
    }
    #sidebar-primary .widget .widget-title{
        font-size: 20px;
    }
    .inner-wrapper {
        margin-left: -15px;
        margin-right: -15px;
    }

    #primary,
    .sidebar,
    #colophon,
    #featured-clients {
        padding-left: 15px;
        padding-right: 15px;
    }


    /*Main slider*/

    #main-slider .cycle-caption {
        padding: 15px;
    }

    #main-slider .cycle-slide {
        width: 100%;
    }

    #main-slider .cycle-caption {
        padding:15px;
    }

    #main-slider .cycle-next {
        right: 20px;
    }

    #main-slider .cycle-prev {
        left: 20px;
    }

    #main-slider .cycle-caption h3,
    #main-slider .cycle-caption h2,
    #main-slider .cycle-caption h1 {
        font-size: 35px;
    }


    td,
    th {
        font-size: 12px;
        padding: 2px;
        text-align: center;
    }

    .main-navigation ul  ul {
        top: 40px;
    }

    .main-navigation ul ul a {
        padding-bottom: 5px;
        padding-top: 5px;
    }

/*--------------------------------------------------------------
 responsive css
--------------------------------------------------------------*/

    .business_point_widget_advanced_recent_posts img {
        float: none;
    }

    /*.business_point_widget_latest_news .latest-news-item,*/
	#featured-content article,
    .services-item ,
    .business_point_widget_services .services-item{
        padding: 15px 15px;
    }

    .business_point_widget_latest_news .latest-news-meta .latest-news-comments {
        clear: both;
        display: block;
        float: none;
    }

    .business_point_widget_testimonials .testimonial-widget {
        margin: 0;
    }



/*--------------------------------------------------------------
 responsive css
--------------------------------------------------------------*/

    .business_point_widget_advanced_recent_posts img {
        float: none;
    }

    
    /*.business_point_widget_latest_news .latest-news-item,*/
	#featured-content article,
    .services-item ,
    .business_point_widget_services .services-item{
        padding: 15px 15px;
    }

    .business_point_widget_latest_news .latest-news-meta .latest-news-comments {
        clear: both;
        display: block;
        float: none;
    }

    .business_point_widget_testimonials .testimonial-widget {
        margin: 0;
    }

}
/*--------------------------------------------------------------
 Responsive css
--------------------------------------------------------------*/

/* All Mobile Sizes (devices and browser) */

@media screen and (max-width: 991px){

    #primary .post .content-wrap {
        position: static;
        width: 100%;
        top: unset;
        transform: none;
    }

    #primary .post .featured-thumb {
        max-width: 100%;
    }

    #primary .post .content-wrap,
    .search-results  #primary article .content-wrap .content-wrap-inner {
        float: left;
        padding-top: 15px;
    }

    #primary .post .featured-thumb, 
    #primary .post .featured-thumb img,
    #primary .post .featured-thumb a{
        width: 100%;
        float: left;
        line-height: 1;
    }

    .single-wrap .single-inner {
        padding:0;
    }

    .single-wrap .single-inner-content {
        margin-top: 0px;
    }

    .search-results  #primary article .featured-thumb, 
    .search-results  #primary article .featured-thumb a,
    .search-results  #primary article .featured-thumb img {
        width: 100%;
    }

    .sidebar form.search-form input[type="submit"],
    .search-no-results .sidebar form.search-form input[type="submit"]{
        width: 100%;
        position: relative;
    }
    
}

@media only screen and (max-width:900px) {


  body{
        padding-top: 0;
    }

    .enabled-sticky-primary-menu  #masthead.fixed {
        top: 39px;
    }
    .enabled-sticky-primary-menu.admin-bar #masthead.fixed {
        top: 87px;
    }

    #mobile-trigger {
        background: #000000 none repeat scroll 0 0;
        display: block;
        left: 0;
        padding: 5px;
        position: fixed;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 999999;
    }
    .logged-in #mobile-trigger {
        top: 45px;
    }   

    #mobile-trigger i {
        background-color: #AE1715 ;
        border: 2px solid #fffffe;
        box-shadow: 0 1px 2px #000000;
        color: #ffffff;
        font-size: 21px;
        padding: 6px 8px;
    }
    .site-layout-boxed #page {
        margin-top: 70px;
    }

    .business_point_widget_latest_news .latest-news-col-4 .latest-news-item {
        width: 50%;
		margin-bottom:15px;
    }
    .business_point_widget_teams .our-team-summary {
        display: none!important;
    }
    .business_point_widget_services .services-column-4 .services-item {
        width: 50%;
    }


    .site-layout-boxed #page {
        padding-top: 0;
    }
    .site-logo-link, .custom-logo-link {
        float: left;
        margin-right: 15px;
        max-width: inherit;
    }
    #site-identity {
        clear: inherit;
        float: left;
    }
	
	/* media css main-slider */	
	#main-slider .item .caption h3 {
		font-size:45px;		
	}
	
	#main-slider .item .caption .slider-meta {
		font-size:20px;		
	}
	
	#home-page-widget-area .business_point_widget_features .features-list .features-detail {
		padding-left:20px;		
	}
	
	.site-branding {
		position:relative;
		z-index:99999999;		
	}

    .business_point_widget_latest_news .latest-news-item {
        margin-bottom: 18px;
    }
	
}

@media screen and (max-width: 850px){

    .post-navigation  .nav-previous, 
    .post-navigation  .nav-next {
        width: 100%;
    }

    .post-navigation  .nav-previous a, 
    .post-navigation  .nav-next a {
        float: left;
    }
}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width:767px) {

    h1 {
        font-size: 22px;
    }

    h2 {
        font-size: 20px;
    }

    h3{
        font-size: 18px;
    }

    h4 {
        font-size:16px;
    }

    h5 {
        font-size: 15px;
    }

    h6 {
        font-size: 14px;
    }
    #sidebar-primary .widget .widget-title{
        font-size: 20px;
    }

   .site-layout-boxed #page .container  {
        width: 100%;
        padding: 0 15px;
    }

    .widget.business_point_widget_call_to_action {
        display: block;
        margin-bottom: 25px;
        padding: 15px;
    }

    .site-layout-boxed #page {
        width: 90%;
        padding: 0 15px;
    }

    .container {
        width: 90%;
        padding: 0 15px;
    }

    .site-layout-boxed #featured-slider,
    .site-layout-boxed #footer-widgets,
    .site-layout-boxed #colophon {
        margin-left: -15px;
        margin-right: -15px;
    }

    #featured-slider, #footer-widgets, #colophon {
        margin-left: 0;
        margin-right: 0;
    }

    #home-page-widget-area .widget-title span {
        margin-bottom: 0;
    }


    .site-branding {
        text-align: left;
    }

    .site-content {
        padding-top: 15px;
    }

    .our-team-item {
        width: 50%;
    }



    /*featured slider style*/

    #main-slider .cycle-caption h3,
    #main-slider .cycle-caption h2,
    #main-slider .cycle-caption h1 {
        font-size: 19px;
        font-weight: bold;
        line-height: 1.4;
        margin-bottom: 6px;
    }

    #main-slider .cycle-prev::after,
    #main-slider .cycle-next::after {
        font-size: 23px;
        padding: 15px;
    }

    #main-slider .cycle-prev::after, #main-slider .cycle-next::after {
        font-size: 23px;
        padding: 5px 10px;
    }

    #main-slider .cycle-caption p {
        font-size: 13px;
        overflow: hidden;
    }

    #main-slider .cycle-pager {
        bottom: 0;
        left: 10px;
    }

    #main-slider .cycle-prev {
        left: 10px;
    }

    #main-slider .cycle-next {
        right: 10px;
    }

    #main-slider .cycle-prev, #main-slider .cycle-next {
        bottom: 10px;
    }
	
	/* main-slider media css */
	#main-slider .item .caption h3 {
	  font-size: 30px;
	}
		
	#main-slider .item .caption .slider-meta {
		font-size: 18px;
		padding: 20px 0px 10px 0px;
	}
	
	#main-slider .item .caption h3:after {
		top:50px;		
	}
	
	#main-slider .item .caption .slider-cta {
    	padding-top: 30px;
	}
	
	#main-slider .item .caption {
		padding-top:20px;		
	}

    #main-slider .cycle-slide .cycle-caption .caption-wrap .button {
        margin-top: 5px;
        padding: 10px 20px;
    }

    #main-slider .cycle-prev i, 
    #main-slider .cycle-next i {
        font-size: 50px;
    }
	
	/*main-slider media css ends */

    /*featured content*/

    #featured-content article {
        float: left;
        padding: 0 15px;
        max-width: 100%;
    }

    #featured-content,
    #featured-content,
    #content {
        clear: both;
        padding-top: 20px;
    }

    #home-page-widget-area {
        clear: both;
        padding: 0px 0;
    }

    #home-page-widget-area .widget + .widget {
        margin-top: 15px;
    }
    #home-page-widget-area .widget {
        padding: 40px 0;
    }


    /*sidebar layout*/

    #primary,
    #sidebar-primary,
    #sidebar-secondary {
        width: 100%;
        clear: both;
        float: none;
    }

    .global-layout-three-columns #primary,
    .global-layout-three-columns .sidebar {
        display: inline-block;
        float: none;
        width: 100%;
    }

     #sidebar-primary {
        margin-top: 30px;
     }

    /*footer widget area*/

    .sibebar{
        margin-top: 15px;
    }

    #footer-widgets .widget-column {
        float: left;
        padding: 0 15px;
        width: 100%;
        margin-bottom: 20px;
    }

    #footer-widgets .widget-column:nth-child(3n) {
        clear: both;
    }

    .services-item {
        width: 50%;
    }

    .our-team-item {
        width: 50%;
    }

    #home-page-widget-area .business_point_widget_call_to_action::before,
    #home-page-widget-area .business_point_widget_call_to_action::after,
    .business_point_widget_call_to_action::after,
    .business_point_widget_call_to_action::before,
    #home-page-widget-area .widget.business_point_widget_call_to_action::after {
        display: none;
    }


    /*--------------------------------------------------------------
    ### Responsive css
    --------------------------------------------------------------*/

    #featured-content .featured-content-column-4 article {
        max-width: 50%;
    }

    .business_point_widget_call_to_action {
        padding: 15px;
    }

    .widget {
        margin: 15px 0 0;
    }

    .testimonial-widget {
        padding: 0;
    }

    .business_point_widget_advanced_recent_posts img {
        float: left;
    }

    .business_point_widget_advanced_recent_posts {
        text-align: left;
    }

    #footer-widgets {
        padding: 30px 15px;
    }
    #featured-clients {
        margin-left: -15px;
        margin-right: -15px;
    }
    #colophon .copyright,
        #colophon .site-info {
        clear: both;
        text-align: center;
        width: 100%;
    }
    #featured-content .featured-content-column-3 article {
        max-width: 100%;
    }
    .business_point_widget_teams .our-team-col-4 .our-team-item,
    .business_point_widget_teams .our-team-col-3 .our-team-item {
        width: 50%;
    }
	
	/* other sections css except slider */
	
	/* Business_Point widget services css */
	.business_point_widget_services .services-column-3 .services-item {
   		 width: 50%;
	}
	
	/* Business_Point widget feature css */
	#home-page-widget-area .business_point_widget_features .feature-image,
	#home-page-widget-area .business_point_widget_features .features-list {
		width:100%;		
	}
	
	#home-page-widget-area .business_point_widget_features .feature-image {
		padding:0;		
	}
	
	#home-page-widget-area .business_point_widget_features .features-list .features-detail {
   	 padding-left: 0;
	}
	
	/* Business_Point widget lates news css */
	.business_point_widget_latest_news .inner-wrapper {
		text-align:center;		
	}
	
	.business_point_widget_latest_news .latest-news-widget .latest-news-item {
		float:none;
		display:inline-block;
		width:60%;
		margin-bottom:15px;		
	}

    .business_point_widget_latest_news .small-items-wrap.layout-style-two .latest-news-item {
        width: 50%;
    }
	
	/* Business_Point widget facts css */
	.business_point_widget_facts .counter-item span.count-icon i {
		font-size:30px;		
	}
	
	.business_point_widget_facts .counter-item span.count {
		font-size:40px;		
	}
	
	/* Business_Point widget contact css */
	#home-page-widget-area  .widget.business_point_widget_contact {
		margin-top:0px;			
	}
	
	#home-page-widget-area {
		padding-bottom:0px;		
	}
	
	.contact-left, 
	.contact-right {
		width:100%;
		padding:0;		
	}
	
	.contact-left {
		margin-bottom:15px;		
	}
	
	#colophon .copyright {
		margin-bottom:5px;		
	}

    .business_point_widget_services .services-item {
        width: 50%;
    }

    .business_point_widget_latest_news .large-item,
    .business_point_widget_latest_news .latest-news-widget .latest-news-item,
    .business_point_widget_latest_news .latest-news-item img,
    .business_point_widget_latest_news .latest-news-thumb a,
    .business_point_widget_latest_news .small-items-wrap,
    .newsletter-form form,
    .error-404.not-found  form.search-form {
        width: 100%;
    }

    .post-navigation .nav-links .nav-next,
    .post-navigation .nav-links .nav-previous {
        float: left;
        width: 100%;
        margin:5px 0px;
    }

    .post-navigation .nav-links .nav-next a,
    .post-navigation .nav-links .nav-previous a{
        float: left;
    }

    .top-left,
    .top-right{
        width: 100%;
        text-align: center;
    }

    .top-right .menu-social-menu-container {
        display: inline-block;
    }

    .pt-testimonials .pt-testimonial-item-wrap,
    .page-template-full-width-page .pt-testimonials .pt-testimonial-item,
    .page-template-default.page .pt-testimonials .pt-testimonial-item,
    .page-template-no-border-page .pt-testimonials  .pt-testimonial-item {
        width: 100%;
    }

     
    .page-template-default.page .pt-testimonials .pt-testimonial-item:nth-child(2) .pt-testimonial-caption, 
    .page-template-full-width-page .pt-testimonials .pt-testimonial-item:nth-child(2) .pt-testimonial-caption, 
    .page-template-full-width-page .pt-testimonials .pt-testimonial-item:nth-child(3) .pt-testimonial-caption, 
    .page-template-no-border-page .pt-testimonials .pt-testimonial-item:nth-child(2) .pt-testimonial-caption, 
    .page-template-no-border-page .pt-testimonials .pt-testimonial-item:nth-child(3) .pt-testimonial-caption {
        border-top:1px solid #ddd;
        padding-top: 20px;
    }


}

@media screen and (max-width: 640px){

    .top-left span {
        display: inline-block;
        width: 100%;
    }
}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width:550px) {
    .business_point_widget_latest_news .latest-news-widget .latest-news-item,
    .business_point_widget_latest_news .small-items-wrap.layout-style-two .latest-news-item {
        width: 100%;
    }
    
    .business_point_widget_latest_news .latest-news-col-4 .latest-news-item,
    .business_point_widget_latest_news .latest-news-col-3 .latest-news-item
    .business_point_widget_latest_news .latest-news-col-2 .latest-news-item
    .business_point_widget_services .services-column-4 .services-item,
    .business_point_widget_services .services-column-3 .services-item
    .business_point_widget_services .services-column-2 .services-item {
        width: 100%;
    }
    .widget.business_point_widget_services .services-item {
        width: 100%;
    }
    .business_point_widget_featured_page img {
        display: block;
        float: none;
        margin: 0 auto;
    }
    .business_point_widget_latest_news .latest-news-item img {
        width:100%;
    }
	
	#home-page-widget-area .widget-title {
		font-size:27px;		
	}
	
	/* css for slider-caption */
	#main-slider .item .caption h3:after {
		top: unset;
		bottom: -12px;
	}
	
	#main-slider .item .caption h3 {
   	 	font-size: 28px;
	 	line-height: 1.5;
	}
	
	#main-slider .item .caption .slider-meta {
    	font-size: 18px;
	}
	
	/*Business_Point features widget css */
	#home-page-widget-area .business_point_widget_features .features-list .features-detail {
   		 padding-left: 15px;
	}
	
	/* Business_Point widget facts css */
    .business_point_widget_facts .fact-fourth .counter-item,
	.business_point_widget_facts .counter-item {
		width:50%;
		margin-bottom:20px;		
	}

    #primary .post  .entry-title {
        font-size: 18px;
    }

    #primary .post .entry-footer span {
        float: left;
        width: 100%;
        margin-bottom: 10px;
    }

    #primary .post .entry-footer span.author {
        float: none;
        width: auto;
        margin-bottom: 0px;
    }
	
}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width:479px) {
    .custom-logo-link,.site-logo-link,
    #site-identity {
        display: block;
        float: none;
        margin-right: 0;
        text-align: center;
        width: 100%;
        max-width: inherit;
    }

    /*main slider */


    #main-slider:hover .cycle-caption,
    #main-slider .cycle-caption {
        padding: 5px;
    }

    #main-slider .cycle-caption h3,
    #main-slider .cycle-caption h2,
    #main-slider .cycle-caption h1 {
        font-size: 16px;
        height: auto;
        margin-bottom: 0;
        overflow: hidden;
    }

    #main-slider .cycle-pager {
        bottom: -3px;
    }

    #main-slider .cycle-caption p {
        display: none;
    }

    #footer-widgets .widget-column {
        width: 100%;
    }

    #footer-widgets .widget-column:first-child {
        border: none;
        margin-top: 0;
        padding-top: 0;
    }

    #footer-widgets .widget-column {
        width: 100%;
        padding-top: 15px;
        margin-top: 15px;
    }

   .business_point_widget_teams .our-team-item {
        width: 100%;
    }

    .client-item {
        display: inline-block;
        margin: 15px 25px;
    }

    #featured-clients .cycle-prev,
    #featured-clients .cycle-next {
        display: none;
    }

    .site-description {
        margin-bottom: 10px;
    }

    .business_point_widget_call_to_action {
        /*border-left: 3px solid #AE1715 ;
        border-right: 3px solid #AE1715 ; */
		border:0;
    }

    #featured-content .featured-content-column-3 article,
    #featured-content .featured-content-column-4 article {
        max-width: 100%;
    }

    .search-form input.search-field {
        width: 76%;
    }

    .search-box .search-box-wrap {
        right: 3%;
        width: 263px;
    }

    .social-links {
        float: right;
        max-width: 208px;
        text-align: right;
    }


    .social-links .business_point_widget_social li {
        display: inline-block;
        float: none;
    }

    .search-box {
        float: right;
        position: relative;
        width: 33px;
    }
    .site-layout-boxed #page .container {
        padding: 0;
        width: 100%;
    }
    .business_point_widget_teams .our-team-col-4 .our-team-item,
    .business_point_widget_teams .our-team-col-3 .our-team-item,
    .business_point_widget_teams .our-team-col-2 .our-team-item {
        width: 100%;
    }
	
	/* css widget title */
	
	#home-page-widget-area .widget-title span::before,
	#home-page-widget-area .widget-title span::after {
		width:25px;
	}
	
	#home-page-widget-area .widget-title span {
		float:left;
		width:100%;		
	}
	
	#home-page-widget-area .widget-title span::before {
		right:unset;
		left:0;		
	}
	
	#home-page-widget-area .widget-title span::after {
		left:unset;
		right:0;		
	}
	
	/* slider caption css */
	#main-slider .item .caption h3 {
		font-size: 22px;
		line-height: 1.2;
	}
	
	#main-slider .item .caption .slider-meta {
    	font-size: 14px;
	}
	
	/*  css Business_Point feature widget */
	#home-page-widget-area .business_point_widget_features .features-list .features-icon i {
		text-align:left;		
	}
	
	/* Business_Point widget facts */
	.business_point_widget_facts .counter-item span.count-text {
		font-size:15px;		
	}

    #primary .post .content-wrap {
        padding: 0px;
    }

    #main-slider .cycle-prev, 
    #main-slider .cycle-next {
        height: 30px;
        width: 30px;
    }

    #main-slider .cycle-prev i, 
    #main-slider .cycle-next i {
        line-height: 30px;
    }

    .business_point_widget_call_to_action .call-to-action-buttons .button {
        margin-bottom: 10px;
    }
	
	.newsletter-form form input[type="submit"] {
        width: 100%;
        position: static;
    }

     #main-slider .cycle-prev i, 
    #main-slider .cycle-next i {
        font-size: 30px;
    }
	
}

@media screen and (max-width:350px){
	
	#home-page-widget-area .widget-title {
    	font-size: 22px;
	}
	
}
@media(min-width: 992px){
    .sidebar .search-form input.search-field{
        padding-right: 90px;
    }
}
.sidebar .search-form input.search-field{
    line-height: initial; 
}
.post .entry-content .readmore-content{
    position:relative;  
    float: right;
}
.post .entry-content .readmore-content:hover{
    text-decoration: underline;
}


/* ======================================= */
figure{margin: 0;}
.entry-content .wp-block-gallery{
    padding-left: 0; 
    margin-left: 0; 
    list-style-type: none;
}
blockquote.wp-block-pullquote{
    padding-left: 1em; 
    padding-right: 1em; 
}
.entry-content .wp-block-archives, 
.entry-content .wp-block-categories, 
.entry-content .wp-block-latest-posts{
    list-style: none; 
    margin-left: 0; 
}

/* Separator
--------------------------------------------- */
.wp-block-separator:not(.is-style-dots),
hr:not(.is-style-dots) {
    background-color: #cccccc;
    border: 0;
    height: 1px;
}

.wp-block-separator:not(.is-style-wide):not(.is-style-dots),
hr:not(.is-style-wide):not(.is-style-dots) {
    width: 100%;
    height: 4px;
    background: transparent;
    margin-top: 20px;
}

.wp-block-separator:not(.is-style-wide):not(.is-style-dots)::before,
hr:not(.is-style-wide):not(.is-style-dots)::before {
    content: '';
    display: block;
    height: 4px;
    width: 40px;
    background: #cccccc;
}

.wp-block-separator.is-style-dots:before,
hr.is-style-dots:before {
    color: #191e23;
    font-size: 22px;
    padding-left: 12px;
}

.entry-content > *.aligncenter,
.entry-summary > *.aligncenter {
  margin-left: auto;
  margin-right: auto;
}

/*@media only screen and (min-width: 768px) {
  .entry-content > *.aligncenter,
  .entry-summary > *.aligncenter {
    max-width: calc(8 * (100vw / 12) - 28px);
  }
}

@media only screen and (min-width: 1168px) {
  .entry-content > *.aligncenter,
  .entry-summary > *.aligncenter {
    max-width: calc(6 * (100vw / 12) - 28px);
  }
}*/

@media only screen and (min-width: 768px) {
  .entry-content > *.aligncenter,
  .entry-summary > *.aligncenter {
     margin-left: 0; 
     margin-right: 0; 
  }
} 

/* ======== NO SIDE BAR =========== */

.site-content{overflow: hidden;}
.global-layout-no-sidebar #main .post, 
.global-layout-no-sidebar #main .page, 
.global-layout-no-sidebar #main article.product{
    border: none; 
    padding: 0;
}

/* == Align wide == */
.global-layout-no-sidebar .entry-content > *.alignwide,
.global-layout-no-sidebar .entry-summary > *.alignwide {
  margin-left: auto;
  margin-right: auto;
  clear: both;
}

@media only screen and (min-width: 768px) {
    .global-layout-no-sidebar .entry-content > *.alignwide,
    .global-layout-no-sidebar .entry-summary > *.alignwide {
    width: 100%;
    max-width: 100%;
  }
}
/* == */

/* == Align Full == */
@media (min-width: 768px) {
    .global-layout-no-sidebar .entry-content>*.alignfull, 
    .global-layout-no-sidebar #primary ul.wp-block-gallery.alignful {
        margin-top: calc( 2 * 20px);
        margin-bottom: calc( 2 * 20px);
        max-width: 1000%
    }
    .global-layout-no-sidebar .entry-content>*.alignwide img,
    .global-layout-no-sidebar .entry-content>*.alignfull img {
        display: block;
        margin: 0 auto
    }
    .global-layout-no-sidebar .entry-content>*.alignfull, 
    .global-layout-no-sidebar #primary ul.wp-block-gallery.alignful {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        width: auto
    }
}
/* == */
div#sticky-wrapper {
    float: left;
    width: 100%;
    position: relative;
    z-index: 99999;
}

.sticky-wrapper.is-sticky .site-header, .site-header{
    background-color: #ffffff;
}

.sticky-wrapper.is-sticky .site-header{
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.business_point_widget_call_to_action{ z-index: 99; }
@media only screen and (max-width:1050px) {
.site-branding {
position:relative;
z-index:99999999;
}
}

.page-id-8 .entry-content img {
    margin-right: 0 !important;
    margin: 0px 0px 30px 45px;
    max-height: 80px;
}