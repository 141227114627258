.site-header {
  position: fixed !important;
  top: 0;
}
header .head-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .site-branding {
    line-height: normal;
    font-size: 0;
  }
}
.hero-section {
  line-height: normal;
  font-size: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.about-section {
  color: #fff;

  h3 {
    color: #fff;
  }
}

.mobile-menu-btn {
  display: none;
}

.mobileMenu {
  margin-top: 0 !important;
  .MuiPaper-root {
    top: 30px;
  }
}

.imageList {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 20px -20px 0;
  justify-content: center;
  align-items: center;
  li {
    flex: 1 25%;
    padding: 20px;
    img {
      width: 100%;
      max-width: 234px;
    }
  }
}

.scrollup{
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}


@media only screen and (max-width: 1050px) {
  .mobile-menu-btn {
    display: block;
  }
  .site-branding {
    margin-top: 0;
    min-width: 180px;
    max-width: 180px;
  }
  #main-nav {
    display: none;
  }
  #masthead-sticky-wrapper {
    height: 80px !important;
  }
  .mean-container .mean-nav {
    margin-top: 50px;
  }
}

.job-card {
  border-bottom: 1px solid #eee;
  background-color: #fcfcfc;
  margin-bottom: 12px;
  transform: scale(1);
  transition: all 0.3s ease-in;
  position: relative;
  &:hover {
    transform: scale(1.01);
    box-shadow: 4px 1px 6px 0px rgba(0, 0, 0, 0.1);
  }
  .CardContent {
    position: relative;
    padding: 15px;
    display: flex;
    .company_logo {
      width: 42px;
      height: 42px;
      position: absolute;
      left: 1em;
      margin-right: 1em;
      vertical-align: middle;
      box-shadow: none;
    }
   
    .position {
      flex: 1 55%;
      h3 {
        font-size: 15px;
        margin: 0;
      }
    }
    .location {
      flex: 1 20%;
      line-height: normal;
    }
    .meta {
      flex: 1 20%;
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: right;
      .full-time {
        color: #90da36;
        font-weight: bold;
      }
      .date {
        text-align: right;
        line-height: 1.5em;
        color: #999;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .imageList li {
    flex: 1 33.33%;
  }
  .job-card { 
    .CardContent {
      flex-wrap: wrap;
      align-items: center;
      .company_logo {
        display: none;
      }
      .position {
        padding: 0;
        flex: 1 60%;
      }
      .location {
        flex: 1 40%;
        text-align: right;
      }
    }
   
  }
}

@media only screen and (max-width: 480px) {
  .imageList li {
    flex: 1 50%;
  }
  .job-card .CardContent .position {
    flex: 1 1 100%;
  }
  .job-card .CardContent .location {
    flex: 1 1 100%;
    text-align: left;
    margin: 0 0 10px;
  }

}
